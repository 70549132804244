export class MetaPixel {
	pageview = () => {
		if (typeof window !== "undefined" && window.fbq) {
			window.fbq("track", "PageView");
		}
	};

	event = (name: MetaPixelEvent, options = {}) => {
		if (typeof window !== "undefined" && window.fbq) {
			window.fbq("track", name, options);
		}
	};
}

export type MetaPixelEvent = MetaPixelStandardEvent | MetaPixelCustomEvent;

// https://developers.facebook.com/docs/meta-pixel/reference
export enum MetaPixelStandardEvent {
	AddToCart = "AddToCart",
	InitiateCheckout = "InitiateCheckout",
	CompleteRegistration = "CompleteRegistration",
}

export enum MetaPixelCustomEvent {
	InitiatePurchase = "InitiatePurchase",
	Login = "Login",
}
