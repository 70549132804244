import React from "react";

export interface SelectBranchModalContext {
	open: boolean;
	onClose: () => void;
	onOpen: () => void;
}
export const SelectBranchModalContext = React.createContext<
	SelectBranchModalContext | Record<string, never>
>({});
