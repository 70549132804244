import React from "react";

import Dialog from "../BasicComponent/Dialog";
import {
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "../BasicComponent/Dialog/Dialog";

interface Props {
	open: boolean;
	onClose: () => void;
	title: string;
	description?: string;
	onSubmit: () => void;
	cancelText: string;
	yesText: string;
}
const WarningIcon = () => (
	<svg
		className="h-6 w-6 text-main-color"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
		aria-hidden="true"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
		/>
	</svg>
);
const ConfirmationDialog: React.FC<Props> = ({
	onClose,
	open,
	cancelText,
	yesText,
	onSubmit,
	title,
	description,
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			icon={<WarningIcon />}
			footer={
				<div>
					<button
						type="button"
						className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
						onClick={onClose}
					>
						{cancelText}
					</button>
					<button
						type="button"
						className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-main-color text-base font-medium text-black hover:bg-main-color-light focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
						onClick={onSubmit}
					>
						{yesText}
					</button>
				</div>
			}
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText className="text-gray-700">
					{description}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmationDialog;
