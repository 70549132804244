/* eslint-disable import/prefer-default-export */
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CloudinaryContext } from "cloudinary-react";

import ConfirmationProvider from "./src/components/Confirmation/ConfirmationProvider";
import SelectBranchModalProvider from "./src/components/SelectBranchModal/SelectBranchModalProvider";
import { BranchProvider } from "./src/context/branchContext";

const queryClient = new QueryClient();
export const wrapRootElement = ({ element }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<ConfirmationProvider>
				<CloudinaryContext cloudName="ewburgers-cloudinary">
					<SelectBranchModalProvider initialState={{ open: false }}>
						<BranchProvider>{element}</BranchProvider>
					</SelectBranchModalProvider>
					<ToastContainer />
				</CloudinaryContext>
			</ConfirmationProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};

// Disable tawk-io for now since it is blocking a lot of button in mobile
// It is also hard to customize its styling
// export const onRenderBody = ({ setPostBodyComponents }) => {
// 	setPostBodyComponents([
// 		<script
// 			key="tawk-io-script"
// 			type="text/javascript"
// 			dangerouslySetInnerHTML={{
// 				__html: `
// 					var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// 					(function(){
// 						var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// 						s1.async=true;
// 						s1.src='https://embed.tawk.to/60c5b5037f4b000ac037538c/1f824m608';
// 						s1.charset='UTF-8';
// 						s1.setAttribute('crossorigin','*');
// 						s0.parentNode.insertBefore(s1,s0);
// 					})();
// 				`,
// 			}}
// 		/>,
// 	]);
// };
