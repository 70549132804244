import React from "react";

export interface ConfirmationOptions {
	title: string;
	description: string;
	yesText: string;
	cancelText: string;
}
const ConfirmationContext = React.createContext<
	// we will pass the opening dialog function directly to consumers
	(options: Partial<ConfirmationOptions>) => Promise<void>
>(Promise.reject);

export default ConfirmationContext;
