import { DeliveryMethod } from "src/generated/graphql";

export const EW_BURGERS_SHOP_ID = 1;
export const DINE_IN_ORDER_FLOW = 1;
export const DELIVERY_ORDER_FLOW = 2;

export const deliveryMethodToOrderFlow = {
	[DeliveryMethod.SelfCollection]: DINE_IN_ORDER_FLOW,
	[DeliveryMethod.Delivery]: DELIVERY_ORDER_FLOW,
};

export enum OrderFlowStatus {
	Inactive = 0,
	Active = 1,
}
