import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
} from "react-query";

import { GraphQLClient } from "graphql-request";

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};

function fetcher<TData, TVariables>(
	client: GraphQLClient,
	query: string,
	variables?: TVariables
) {
	return async (): Promise<TData> =>
		client.request<TData, TVariables>(query, variables);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
	BigInt: any;
	/** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
	Date: any;
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
	DateTime: any;
	/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSONObject: any;
	/** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
	Time: any;
	/** The `Upload` scalar type represents a file upload. */
	Upload: any;
};

export type AddProductToCartCoupon = {
	code: Scalars["String"];
	discountValue: Scalars["Int"];
	originalPrice: Scalars["Int"];
};

export type AddPromotionToCartInput = {
	promotionId: Scalars["Int"];
	quantity: Scalars["Int"];
	requirementIdProductIds: Array<RequirementIdProductId>;
	rewardIdProductIds: Array<RewardIdProductId>;
	branchId: Scalars["Int"];
	orderFlowId: Scalars["Int"];
};

export type AllMenuSection = {
	__typename?: "AllMenuSection";
	special: Array<MenuSection>;
	main: Array<MenuSection>;
};

export type AuditLog = {
	__typename?: "AuditLog";
	id: Scalars["Int"];
	targetPk: Scalars["String"];
	changes: Scalars["String"];
	oldValue: Scalars["String"];
	newValue: Scalars["String"];
	operator: Scalars["String"];
	createdAt: Scalars["DateTime"];
};

export type AuditLogOrderByInput = {
	createdAt?: Maybe<SortOrder>;
};

export type AuditLogWhereInput = {
	resource?: Maybe<Scalars["String"]>;
};

export type Branch = {
	__typename?: "Branch";
	id: Scalars["Int"];
	address: Scalars["String"];
	name: Scalars["String"];
	countryCode: Scalars["String"];
	phone: Scalars["String"];
	postalCode: Scalars["String"];
	city: Scalars["String"];
	region: Scalars["String"];
	longitude: Scalars["String"];
	latitude: Scalars["String"];
	timezone: Scalars["String"];
	googleMapLink: Scalars["String"];
	taxType: TaxType;
	operatingTimes: Array<OperatingTime>;
	country: Scalars["String"];
	shopId: Scalars["BigInt"];
	taxes: Array<Tax>;
	shop: Shop;
};

export type BranchProduct = {
	__typename?: "BranchProduct";
	id: Scalars["BigInt"];
	branchId: Scalars["Int"];
	productId: Scalars["Int"];
	price: Scalars["Int"];
	position: Scalars["Int"];
	status: Scalars["Int"];
	validFrom: Scalars["Int"];
	validUntil: Scalars["Int"];
	orderFlowIds?: Maybe<Array<Scalars["BigInt"]>>;
	modifierGroups?: Maybe<Array<ModifierGroup>>;
	modifierGroupIds?: Maybe<Array<Scalars["BigInt"]>>;
	product: Product;
	branch: Branch;
};

export type BranchProductWhereInput = {
	id?: Maybe<IntFilter>;
	branchId?: Maybe<IntFilter>;
};

export type BranchWhereInput = {
	id?: Maybe<IntFilter>;
	code?: Maybe<StringFilter>;
	shopId?: Maybe<IntFilter>;
};

export type Campaign = {
	__typename?: "Campaign";
	id: Scalars["BigInt"];
	branchId: Scalars["BigInt"];
	name: Scalars["String"];
	description: Scalars["String"];
	status: Scalars["Int"];
	discountUnit: Scalars["Int"];
	discountValue: Scalars["Int"];
	discountLevel: Scalars["Int"];
	startAt: Scalars["Int"];
	endAt: Scalars["Int"];
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
	extraData?: Maybe<Scalars["String"]>;
	campaignBranches: Array<CampaignBranch>;
};

export type CampaignBranch = {
	__typename?: "CampaignBranch";
	branchId: Scalars["Int"];
	status: Scalars["Int"];
};

export type CampaignOrderByInput = {
	createdAt?: Maybe<SortOrder>;
};

export type CampaignWhereInput = {
	id?: Maybe<IntFilter>;
	status?: Maybe<IntFilter>;
	branchId?: Maybe<IntFilter>;
	name?: Maybe<StringFilter>;
};

export type Cart = {
	__typename?: "Cart";
	id: Scalars["Int"];
	sessionId: Scalars["String"];
	productConnection: Array<CartProduct>;
	promotionCarts: Array<PromotionCart>;
	totalItems: Scalars["Int"];
	totalProductsPrice: Scalars["Int"];
};

export type CartOrderByInput = {
	id?: Maybe<SortOrder>;
};

export type CartProduct = {
	__typename?: "CartProduct";
	id: Scalars["Int"];
	quantity: Scalars["Int"];
	cartId: Scalars["Int"];
	productId: Scalars["Int"];
	name: Scalars["String"];
	price: Scalars["Int"];
	status: CartProductStatus;
	extraData: Scalars["String"];
	product: Product;
	addons: Array<CartProductProductAddOn>;
	subCartProducts: Array<SubCartProduct>;
};

export type CartProductOrderByInput = {
	id?: Maybe<SortOrder>;
};

export type CartProductProductAddOn = {
	__typename?: "CartProductProductAddOn";
	cartProductId: Scalars["Int"];
	productAddOnId: Scalars["Int"];
	productAddOn: ProductAddOn;
};

export enum CartProductStatus {
	Cancelled = "CANCELLED",
	New = "NEW",
	Preparing = "PREPARING",
	Ready = "READY",
}

export type CartProductWhereInput = {
	id?: Maybe<IntFilter>;
	cartId?: Maybe<IntFilter>;
};

export type CartWhereInput = {
	id?: Maybe<IntFilter>;
};

export type CheckoutInput = {
	address: Scalars["String"];
	branchId: Scalars["Int"];
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	phone: Scalars["String"];
	email: Scalars["String"];
	deliveryMethod: DeliveryMethod;
	paymentMethod: PaymentMethod;
	orderFlowId: Scalars["Int"];
	comment?: Maybe<Scalars["String"]>;
	failureRedirectURL?: Maybe<Scalars["String"]>;
	successRedirectURL?: Maybe<Scalars["String"]>;
};

export type CheckoutResponse = {
	__typename?: "CheckoutResponse";
	invoiceUrl?: Maybe<Scalars["String"]>;
	success: Scalars["Boolean"];
	order: Order;
};

export type ConfirmEmailResponse = {
	__typename?: "ConfirmEmailResponse";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
};

export type ConfirmPhoneResponse = {
	__typename?: "ConfirmPhoneResponse";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
};

export enum ConfirmationChannel {
	Email = "EMAIL",
	Phone = "PHONE",
}

export type Coupon = {
	__typename?: "Coupon";
	id: Scalars["BigInt"];
	campaignId: Scalars["BigInt"];
	userId: Scalars["String"];
	code: Scalars["String"];
	description: Scalars["String"];
	userPhone: Scalars["String"];
	userEmail: Scalars["String"];
	userName: Scalars["String"];
	status: Scalars["Int"];
	type: Scalars["Int"];
	maxQuota: Scalars["Int"];
	remainingQuota: Scalars["Int"];
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
	couponBranchProducts: Array<CouponBranchProduct>;
};

export type CouponBranchProduct = {
	__typename?: "CouponBranchProduct";
	couponId: Scalars["BigInt"];
	branchProductId: Scalars["BigInt"];
	quantity: Scalars["Int"];
	productId: Scalars["BigInt"];
	status: Scalars["Int"];
	discountValue: Scalars["Int"];
};

export type CouponOrderByInput = {
	createdAt?: Maybe<SortOrder>;
};

export type CouponWhereInput = {
	code?: Maybe<StringFilter>;
	campaignId?: Maybe<IntFilter>;
};

export type CouponWithCampaign = {
	__typename?: "CouponWithCampaign";
	id: Scalars["BigInt"];
	campaignId: Scalars["BigInt"];
	userId: Scalars["String"];
	code: Scalars["String"];
	description: Scalars["String"];
	userPhone: Scalars["String"];
	userEmail: Scalars["String"];
	userName: Scalars["String"];
	status: Scalars["Int"];
	type: Scalars["Int"];
	maxQuota: Scalars["Int"];
	remainingQuota: Scalars["Int"];
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
	discountLevel: Scalars["Int"];
};

export type CreateBranchInput = {
	address: Scalars["String"];
	name: Scalars["String"];
	postalCode: Scalars["String"];
	phone: Scalars["String"];
	timezone: Scalars["String"];
	countryCode: Scalars["String"];
	googleMapLink: Scalars["String"];
	taxType: TaxType;
	operatingTimes: Array<CreateOperatingTimeInput>;
	longitude: Scalars["String"];
	latitude: Scalars["String"];
	city: Scalars["String"];
	region: Scalars["String"];
	shopId: Scalars["Int"];
};

export type CreateBranchProductInput = {
	branchId: Scalars["Int"];
	position: Scalars["Int"];
	price: Scalars["Int"];
	status: Scalars["Int"];
	validFrom: Scalars["Int"];
	validUntil?: Maybe<Scalars["Int"]>;
	orderFlowIds?: Maybe<Array<Scalars["Int"]>>;
	modifierGroupIds?: Maybe<Array<Scalars["Int"]>>;
};

export type CreateCampaignBranchInput = {
	branchId: Scalars["Int"];
	status: Scalars["Int"];
};

export type CreateCampaignInput = {
	name: Scalars["String"];
	description: Scalars["String"];
	campaignBranches: Array<CreateCampaignBranchInput>;
	status: Scalars["Int"];
	discountUnit: Scalars["Int"];
	discountValue: Scalars["Int"];
	discountLevel: Scalars["Int"];
	startAt: Scalars["Int"];
	endAt: Scalars["Int"];
};

export type CreateCategoryInput = {
	name: Scalars["String"];
	position?: Maybe<Scalars["Int"]>;
	status?: Maybe<Scalars["Int"]>;
	validFrom: Scalars["Int"];
	validUntil?: Maybe<Scalars["Int"]>;
};

export type CreateConfirmationInput = {
	method: ConfirmationChannel;
	couponCode?: Maybe<Scalars["String"]>;
};

export type CreateConfirmationResponse = {
	__typename?: "CreateConfirmationResponse";
	success: Scalars["Boolean"];
	message?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
};

export type CreateCouponBranchProductInput = {
	branchProductId: Scalars["Int"];
	quantity: Scalars["Int"];
	discountValue: Scalars["Int"];
	status: Scalars["Int"];
};

export type CreateCouponInput = {
	code: Scalars["String"];
	campaignId: Scalars["BigInt"];
	status: Scalars["Int"];
	maxQuota: Scalars["Int"];
	type: Scalars["Int"];
	userName: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	userEmail?: Maybe<Scalars["String"]>;
	userPhone?: Maybe<Scalars["String"]>;
	couponBranchProducts: Array<CreateCouponBranchProductInput>;
};

export type CreateMealItemCategory = {
	quantity: Scalars["Int"];
	categoryId: Scalars["Int"];
};

export type CreateMealItemProduct = {
	quantity: Scalars["Int"];
	childId: Scalars["Int"];
};

export type CreateMembershipInput = {
	name: Scalars["String"];
	phone: Scalars["String"];
	password: Scalars["String"];
	externalId: Scalars["String"];
	membershipPartnerId: Scalars["Int"];
	email?: Maybe<Scalars["String"]>;
};

export type CreateMembershipPartnerInput = {
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	status: Scalars["Int"];
	discounts?: Maybe<Array<MembershipDiscountInput>>;
	inherit_discounts_from_global_settings: Scalars["Boolean"];
};

export type CreateMembershipResponse = {
	__typename?: "CreateMembershipResponse";
	id: Scalars["BigInt"];
	userId: Scalars["String"];
	membershipPartnerId: Scalars["Int"];
	membershipNumber: Scalars["String"];
	status: Scalars["Int"];
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
};

export type CreateModifierGroupInput = {
	branchId: Scalars["Int"];
	name: Scalars["String"];
	description: Scalars["String"];
	minQuantity: Scalars["Int"];
	maxQuantity: Scalars["Int"];
	type: Scalars["Int"];
	status: Scalars["Int"];
	position: Scalars["Int"];
	modifiers?: Maybe<Array<CreateModifierInput>>;
};

export type CreateModifierInput = {
	price: Scalars["Int"];
	ref: Scalars["Int"];
	status: Scalars["Int"];
};

export type CreateOperatingTimeInput = {
	closeTime: Scalars["String"];
	openTime: Scalars["String"];
	dayOfWeek: Scalars["Int"];
};

export type CreateOrUpdateModifierGroupInput = {
	id?: Maybe<Scalars["Int"]>;
	name: Scalars["String"];
	minQuantity: Scalars["Int"];
	maxQuantity: Scalars["Int"];
	type: Scalars["Int"];
	status: Scalars["Int"];
	modifiers?: Maybe<Array<CreateOrUpdateModifierInput>>;
};

export type CreateOrUpdateModifierInput = {
	id?: Maybe<Scalars["Int"]>;
	price: Scalars["Int"];
	ref: Scalars["Int"];
	status: Scalars["Int"];
};

export type CreateProductAddOnInput = {
	name: Scalars["String"];
	price: Scalars["Int"];
	description?: Maybe<Scalars["String"]>;
	image?: Maybe<Scalars["String"]>;
};

export type CreateProductInput = {
	name: Scalars["String"];
	image?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	imageUpload?: Maybe<Scalars["Upload"]>;
	categoryId?: Maybe<Scalars["Int"]>;
	productAddOnIds?: Maybe<Array<Scalars["Int"]>>;
	productLabels?: Maybe<Array<CreateProductProductLabelInput>>;
	mealItemProducts?: Maybe<Array<CreateMealItemProduct>>;
	mealItemCategories?: Maybe<Array<CreateMealItemCategory>>;
	branchProducts?: Maybe<Array<CreateBranchProductInput>>;
};

export type CreateProductLabelInput = {
	name: Scalars["String"];
};

export type CreateProductProductLabelInput = {
	product_label_id: Scalars["Int"];
};

export type CreatePromotionInput = {
	name: Scalars["String"];
	imageUpload: Scalars["Upload"];
	validFrom: Scalars["Date"];
	validUntil: Scalars["Date"];
	requirements: Array<CreatePromotionRequirementInput>;
	rewards: Array<CreatePromotionRewardInput>;
	branchIds: Array<Scalars["Int"]>;
};

export type CreatePromotionRequirementInput = {
	id?: Maybe<Scalars["Int"]>;
	categoryId?: Maybe<Scalars["Int"]>;
	quantity: Scalars["Int"];
	productId?: Maybe<Scalars["Int"]>;
};

export type CreatePromotionRewardInput = {
	id?: Maybe<Scalars["Int"]>;
	quantity?: Maybe<Scalars["Int"]>;
	productId?: Maybe<Scalars["Int"]>;
	categoryId?: Maybe<Scalars["Int"]>;
	discountUnit?: Maybe<DiscountUnit>;
	discountValue?: Maybe<Scalars["Int"]>;
};

export type CreateSubCartProductWithoutCartProductInput = {
	mealItemCategoryId?: Maybe<Scalars["Int"]>;
	productId: Scalars["Int"];
	connectionType: SubCartProductConnectionType;
};

export type CreateTaxInput = {
	name: Scalars["String"];
	percentage: Scalars["Int"];
	branchIds?: Maybe<Array<Scalars["Int"]>>;
};

export type DateFilter = {
	lte?: Maybe<Scalars["Date"]>;
	lt?: Maybe<Scalars["Date"]>;
	gt?: Maybe<Scalars["Date"]>;
	gte?: Maybe<Scalars["Date"]>;
};

export enum DeliveryMethod {
	Delivery = "DELIVERY",
	SelfCollection = "SELF_COLLECTION",
	EmptyEnumValue = "EMPTY_ENUM_VALUE",
}

export enum DiscountUnit {
	Currency = "CURRENCY",
	Percentage = "PERCENTAGE",
}

export type FeatureToggle = {
	__typename?: "FeatureToggle";
	id: Scalars["Int"];
	key: Scalars["String"];
	value: Scalars["Boolean"];
	createdAt?: Maybe<Scalars["DateTime"]>;
	updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FeatureToggleWhereInput = {
	id?: Maybe<IntFilter>;
};

export type File = {
	__typename?: "File";
	name: Scalars["String"];
	url: Scalars["String"];
};

export type FileLog = {
	__typename?: "FileLog";
	id: Scalars["BigInt"];
	sourceFile: File;
	status: Scalars["Int"];
	resultFiles: Array<ResultFile>;
	operator: Scalars["String"];
	createdAt: Scalars["BigInt"];
};

export type FileLogWhereInput = {
	workflow?: Maybe<Scalars["Int"]>;
};

export type ForgotPasswordInput = {
	email?: Maybe<Scalars["String"]>;
};

export type ForgotPasswordResponse = {
	__typename?: "ForgotPasswordResponse";
	message?: Maybe<Scalars["String"]>;
	success?: Maybe<Scalars["Boolean"]>;
};

export type GetSignedUrlResponse = {
	__typename?: "GetSignedUrlResponse";
	signed_url: Scalars["String"];
};

export type GetUserMembershipResponse = {
	__typename?: "GetUserMembershipResponse";
	qr: Scalars["String"];
	membershipNumber: Scalars["String"];
	isActive: Scalars["Boolean"];
	referralFrom: Scalars["String"];
	isReferralFromUsable: Scalars["Boolean"];
	referralFromUnusableReason: Scalars["String"];
	referralCode: Scalars["String"];
	isReferralUsable: Scalars["Boolean"];
	referralUnusableReason: Scalars["String"];
	membershipPartnerName: Scalars["String"];
	discounts: Array<MembershipDiscount>;
	referralDiscount?: Maybe<MembershipDiscount>;
};

export type HistoryOrder = {
	__typename?: "HistoryOrder";
	id: Scalars["Int"];
	branchId: Scalars["Int"];
	address: Scalars["String"];
	code: Scalars["String"];
	status: OrderStatus;
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	scheduledAt: Scalars["Int"];
	comment?: Maybe<Scalars["String"]>;
	orderFlowId: Scalars["BigInt"];
	snapshot?: Maybe<OrderSnapshot>;
	branch: Branch;
};

export type IntFilter = {
	equals?: Maybe<Scalars["Int"]>;
	in?: Maybe<Array<Scalars["Int"]>>;
};

export type IsForgotPasswordTokenValidInput = {
	tokenForgotPassword?: Maybe<Scalars["String"]>;
};

export type IsForgotPasswordTokenValidResponse = {
	__typename?: "IsForgotPasswordTokenValidResponse";
	success?: Maybe<Scalars["Boolean"]>;
	message?: Maybe<Scalars["String"]>;
};

export type LoginInput = {
	email?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	password: Scalars["String"];
	couponCode?: Maybe<Scalars["String"]>;
};

export type LoginResponse = {
	__typename?: "LoginResponse";
	user?: Maybe<User>;
	isFirstLogin?: Maybe<Scalars["Boolean"]>;
	message?: Maybe<Scalars["String"]>;
};

export type MeResponse = {
	__typename?: "MeResponse";
	id: Scalars["String"];
	email?: Maybe<Scalars["String"]>;
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	countryCode?: Maybe<Scalars["String"]>;
	couponCode?: Maybe<Scalars["String"]>;
};

export type MealItemCategory = {
	__typename?: "MealItemCategory";
	id: Scalars["Int"];
	parentId: Scalars["Int"];
	categoryId: Scalars["Int"];
	quantity: Scalars["Int"];
	parentProduct: Product;
	category: ProductCategory;
};

export type MealItemCoupon = {
	__typename?: "MealItemCoupon";
	code: Scalars["String"];
	discountValue: Scalars["Int"];
};

export type MealItemProduct = {
	__typename?: "MealItemProduct";
	parentId: Scalars["Int"];
	childId: Scalars["Int"];
	quantity: Scalars["Int"];
	parentProduct: Product;
	childProduct: Product;
};

export type Membership = {
	__typename?: "Membership";
	id: Scalars["BigInt"];
	userId: Scalars["String"];
	membershipPartnerId: Scalars["Int"];
	membershipNumber: Scalars["String"];
	status: Scalars["Int"];
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
	isActive: Scalars["Boolean"];
};

export type MembershipDiscount = {
	__typename?: "MembershipDiscount";
	name: Scalars["String"];
	unit: Scalars["Int"];
	value: Scalars["Int"];
	isReferral: Scalars["Boolean"];
};

export type MembershipDiscountInput = {
	unit: Scalars["Int"];
	value: Scalars["Int"];
	start: Scalars["Int"];
	end?: Maybe<Scalars["Int"]>;
};

export type MembershipDiscountSetting = {
	__typename?: "MembershipDiscountSetting";
	name: Scalars["String"];
	status: Scalars["Int"];
	unit: Scalars["Int"];
	value: Scalars["Int"];
	min_successful_order_count: Scalars["Int"];
	tier: Scalars["Int"];
	is_default: Scalars["Boolean"];
	is_referral: Scalars["Boolean"];
};

export type MembershipDiscountSettingInput = {
	name: Scalars["String"];
	status: Scalars["Int"];
	unit: Scalars["Int"];
	value: Scalars["Int"];
	min_successful_order_count: Scalars["Int"];
	tier: Scalars["Int"];
	is_default: Scalars["Boolean"];
	is_referral: Scalars["Boolean"];
};

export type MembershipPartner = {
	__typename?: "MembershipPartner";
	partnerId: Scalars["Int"];
	name: Scalars["String"];
	description: Scalars["String"];
	status: Scalars["Int"];
	createdAt: Scalars["Int"];
	discounts: Array<MembershipPartnerDiscount>;
	inherit_discounts_from_global_settings: Scalars["Boolean"];
};

export type MembershipPartnerDiscount = {
	__typename?: "MembershipPartnerDiscount";
	unit: Scalars["Int"];
	value: Scalars["Int"];
	start: Scalars["Int"];
	end?: Maybe<Scalars["Int"]>;
};

export type MembershipPartnerWhereInput = {
	partnerId?: Maybe<IntFilter>;
	name?: Maybe<StringFilter>;
	status?: Maybe<IntFilter>;
};

export type MembershipSetting = {
	__typename?: "MembershipSetting";
	discounts: Array<MembershipDiscountSetting>;
};

export type MembershipSettingUpdateInput = {
	discounts: Array<MembershipDiscountSettingInput>;
};

export type MembershipWhereInput = {
	userId?: Maybe<StringFilter>;
	membershipPartnerId?: Maybe<IntFilter>;
	membershipNumber?: Maybe<StringFilter>;
	status?: Maybe<IntFilter>;
	phone?: Maybe<StringFilter>;
};

export type MenuSection = {
	__typename?: "MenuSection";
	id: Scalars["Int"];
	name: Scalars["String"];
	position: Scalars["Int"];
	items?: Maybe<Array<MenuSectionItem>>;
};

export type MenuSectionItem = {
	__typename?: "MenuSectionItem";
	id: Scalars["Int"];
	name: Scalars["String"];
	image: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	slug: Scalars["String"];
	price: Scalars["Int"];
	position: Scalars["Int"];
	coupon?: Maybe<MealItemCoupon>;
	labels?: Maybe<Array<MenuSectionItemLabel>>;
	cloudinaryPublicId?: Maybe<Scalars["String"]>;
};

export type MenuSectionItemCloudinaryPublicIdArgs = {
	publicIdSegments?: Maybe<Scalars["Int"]>;
};

export type MenuSectionItemLabel = {
	__typename?: "MenuSectionItemLabel";
	id: Scalars["Int"];
	name: Scalars["String"];
	position: Scalars["Int"];
};

export type Modifier = {
	__typename?: "Modifier";
	id: Scalars["BigInt"];
	modifierGroupId: Scalars["BigInt"];
	modifierParentId: Scalars["BigInt"];
	price: Scalars["Int"];
	ref: Scalars["Int"];
	position: Scalars["Int"];
	status: Scalars["Int"];
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
};

export type ModifierGroup = {
	__typename?: "ModifierGroup";
	id: Scalars["BigInt"];
	branchId: Scalars["Int"];
	name: Scalars["String"];
	description: Scalars["String"];
	minQuantity: Scalars["Int"];
	maxQuantity: Scalars["Int"];
	position: Scalars["Int"];
	type: Scalars["Int"];
	status: Scalars["Int"];
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
	modifiers?: Maybe<Array<Modifier>>;
};

export type ModifierGroupOrderByInput = {
	id?: Maybe<SortOrder>;
};

export type ModifierGroupWhereInput = {
	id?: Maybe<IntFilter>;
	status?: Maybe<IntFilter>;
	branchId?: Maybe<IntFilter>;
};

export type Mutation = {
	__typename?: "Mutation";
	createCategory: ProductCategory;
	updateCategory: ProductCategory;
	deleteCategory: ProductCategory;
	forgotPassword: ForgotPasswordResponse;
	isForgotPasswordTokenValid: IsForgotPasswordTokenValidResponse;
	resetPassword: ResetPasswordResponse;
	login: LoginResponse;
	register: RegisterResult;
	logout: Scalars["Boolean"];
	createConfirmation: CreateConfirmationResponse;
	confirmPhone: ConfirmPhoneResponse;
	confirmEmail: ConfirmEmailResponse;
	createBranch: Branch;
	updateBranch: Branch;
	createCampaign: Campaign;
	updateCampaign: Campaign;
	clearCart: Scalars["String"];
	deleteAndClearCart: Scalars["Boolean"];
	addProductToCartWithAddOns: CartProduct;
	deleteCartProduct: CartProduct;
	updateCartProduct: CartProduct;
	/** For admin */
	updateCartProductStatus: CartProduct;
	createCoupon: Coupon;
	updateCoupon: Coupon;
	validateCoupon: ValidateCouponResponse;
	/** @deprecated use Mutation.updateOrderAndNotify instead */
	notifyOrderStatusChange: Scalars["Boolean"];
	checkout: CheckoutResponse;
	getSignedUrl: GetSignedUrlResponse;
	updateFeatureToggle: FeatureToggle;
	createMembership: CreateMembershipResponse;
	updateMembership: Membership;
	createMembershipPartner: MembershipPartner;
	updateMembershipPartner: MembershipPartner;
	updateMembershipSettings?: Maybe<MembershipSetting>;
	createModifierGroup: ModifierGroup;
	updateModifierGroup: ModifierGroup;
	/** @deprecated use Mutation.updateOrderAndNotify instead */
	updateOrder: Order;
	updateOrderAndNotify: Order;
	createProduct: Product;
	updateProduct: Product;
	deleteProduct: Product;
	createProductAddOn: ProductAddOn;
	deleteProductAddOn: ProductAddOn;
	updateProductAddOn: ProductAddOn;
	createProductLabel: ProductLabel;
	updateProductLabel: ProductLabel;
	deleteProductLabel: ProductLabel;
	createPromotion: Promotion;
	updatePromotion: Promotion;
	deletePromotion: Promotion;
	deleteManyPromotions: Array<Scalars["Int"]>;
	addPromotionToCart: PromotionCart;
	updatePromotionCart: PromotionCart;
	deletePromotionCart: PromotionCart;
	createTax: Tax;
	updateTax: Tax;
	deleteTax: Tax;
	updateProfile: User;
};

export type MutationCreateCategoryArgs = {
	values: CreateCategoryInput;
};

export type MutationUpdateCategoryArgs = {
	id: Scalars["Int"];
	values: UpdateCategoryInput;
};

export type MutationDeleteCategoryArgs = {
	id: Scalars["Int"];
};

export type MutationForgotPasswordArgs = {
	input?: Maybe<ForgotPasswordInput>;
};

export type MutationIsForgotPasswordTokenValidArgs = {
	input?: Maybe<IsForgotPasswordTokenValidInput>;
};

export type MutationResetPasswordArgs = {
	input?: Maybe<ResetPasswordInput>;
};

export type MutationLoginArgs = {
	input?: Maybe<LoginInput>;
	role?: Maybe<Scalars["String"]>;
	isAdmin?: Maybe<Scalars["Boolean"]>;
};

export type MutationRegisterArgs = {
	input?: Maybe<RegisterInput>;
};

export type MutationLogoutArgs = {
	isAdmin?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateConfirmationArgs = {
	input: CreateConfirmationInput;
};

export type MutationConfirmPhoneArgs = {
	otp: Scalars["String"];
};

export type MutationConfirmEmailArgs = {
	token: Scalars["String"];
};

export type MutationCreateBranchArgs = {
	values: CreateBranchInput;
};

export type MutationUpdateBranchArgs = {
	id: Scalars["Int"];
	values: UpdateBranchInput;
};

export type MutationCreateCampaignArgs = {
	input?: Maybe<CreateCampaignInput>;
};

export type MutationUpdateCampaignArgs = {
	input?: Maybe<UpdateCampaignInput>;
};

export type MutationAddProductToCartWithAddOnsArgs = {
	productId: Scalars["Int"];
	branchId: Scalars["Int"];
	orderFlowId: Scalars["Int"];
	quantity?: Maybe<Scalars["Int"]>;
	productAddOnIds: Array<Scalars["Int"]>;
	subProducts: Array<Maybe<CreateSubCartProductWithoutCartProductInput>>;
	name: Scalars["String"];
	price: Scalars["Int"];
	coupon?: Maybe<AddProductToCartCoupon>;
};

export type MutationDeleteCartProductArgs = {
	id: Scalars["Int"];
};

export type MutationUpdateCartProductArgs = {
	id: Scalars["Int"];
	values: UpdateCartProductInput;
};

export type MutationUpdateCartProductStatusArgs = {
	id: Scalars["Int"];
	status: CartProductStatus;
};

export type MutationCreateCouponArgs = {
	input: CreateCouponInput;
};

export type MutationUpdateCouponArgs = {
	input: UpdateCouponInput;
};

export type MutationValidateCouponArgs = {
	input: ValidateCouponInput;
};

export type MutationNotifyOrderStatusChangeArgs = {
	id: Scalars["Int"];
};

export type MutationCheckoutArgs = {
	input?: Maybe<CheckoutInput>;
};

export type MutationGetSignedUrlArgs = {
	workflow: Scalars["String"];
};

export type MutationUpdateFeatureToggleArgs = {
	id: Scalars["Int"];
	value: Scalars["Boolean"];
	key: Scalars["String"];
};

export type MutationCreateMembershipArgs = {
	input: CreateMembershipInput;
};

export type MutationUpdateMembershipArgs = {
	input?: Maybe<UpdateMembershipInput>;
};

export type MutationCreateMembershipPartnerArgs = {
	input: CreateMembershipPartnerInput;
};

export type MutationUpdateMembershipPartnerArgs = {
	input: UpdateMembershipPartnerInput;
};

export type MutationUpdateMembershipSettingsArgs = {
	input: MembershipSettingUpdateInput;
};

export type MutationCreateModifierGroupArgs = {
	values: CreateModifierGroupInput;
};

export type MutationUpdateModifierGroupArgs = {
	id: Scalars["Int"];
	values: UpdateModifierGroupInput;
};

export type MutationUpdateOrderArgs = {
	id: Scalars["Int"];
	values: UpdateOrderInput;
};

export type MutationUpdateOrderAndNotifyArgs = {
	id: Scalars["Int"];
	values: UpdateOrderInput;
};

export type MutationCreateProductArgs = {
	values: CreateProductInput;
};

export type MutationUpdateProductArgs = {
	id: Scalars["Int"];
	values: UpdateProductInput;
};

export type MutationDeleteProductArgs = {
	id: Scalars["Int"];
};

export type MutationCreateProductAddOnArgs = {
	values?: Maybe<CreateProductAddOnInput>;
};

export type MutationDeleteProductAddOnArgs = {
	id: Scalars["Int"];
};

export type MutationUpdateProductAddOnArgs = {
	id: Scalars["Int"];
	values?: Maybe<UpdateProductAddOnInput>;
};

export type MutationCreateProductLabelArgs = {
	values: CreateProductLabelInput;
};

export type MutationUpdateProductLabelArgs = {
	id: Scalars["Int"];
	values: UpdateProductLabelInput;
};

export type MutationDeleteProductLabelArgs = {
	id: Scalars["Int"];
};

export type MutationCreatePromotionArgs = {
	values: CreatePromotionInput;
};

export type MutationUpdatePromotionArgs = {
	id: Scalars["Int"];
	values: UpdatePromotionInput;
};

export type MutationDeletePromotionArgs = {
	id: Scalars["Int"];
};

export type MutationDeleteManyPromotionsArgs = {
	where?: Maybe<PromotionWhereInput>;
};

export type MutationAddPromotionToCartArgs = {
	values: AddPromotionToCartInput;
};

export type MutationUpdatePromotionCartArgs = {
	id: Scalars["Int"];
	values: UpdatePromotionCartInput;
};

export type MutationDeletePromotionCartArgs = {
	id: Scalars["Int"];
};

export type MutationCreateTaxArgs = {
	values: CreateTaxInput;
};

export type MutationUpdateTaxArgs = {
	id: Scalars["Int"];
	values: UpdateTaxInput;
};

export type MutationDeleteTaxArgs = {
	id: Scalars["Int"];
};

export type MutationUpdateProfileArgs = {
	values?: Maybe<UpdateProfileInput>;
};

export type MyPermissionResult = {
	__typename?: "MyPermissionResult";
	roleNames: Array<Scalars["String"]>;
	permissionCodes: Array<Scalars["String"]>;
};

export type OperatingTime = {
	__typename?: "OperatingTime";
	closeTime: Scalars["String"];
	openTime: Scalars["String"];
	id: Scalars["Int"];
	dayOfWeek: Scalars["Int"];
};

export type Order = {
	__typename?: "Order";
	id: Scalars["Int"];
	branchId: Scalars["Int"];
	address: Scalars["String"];
	cartId: Scalars["Int"];
	code: Scalars["String"];
	contactEmail: Scalars["String"];
	contactFirstname: Scalars["String"];
	contactLastname: Scalars["String"];
	phone: Scalars["String"];
	countryCode: Scalars["String"];
	deliveryMethod: DeliveryMethod;
	status: OrderStatus;
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	scheduledAt: Scalars["Int"];
	userId?: Maybe<Scalars["String"]>;
	comment?: Maybe<Scalars["String"]>;
	snapshot: Scalars["String"];
	orderFlowId: Scalars["BigInt"];
	user?: Maybe<User>;
	cart: Cart;
	transaction?: Maybe<Transaction>;
	branch: Branch;
	shippings: Array<Shipping>;
};

export type OrderByInputOrderByInput = {
	id?: Maybe<SortOrder>;
};

export type OrderFlow = {
	__typename?: "OrderFlow";
	id: Scalars["BigInt"];
	name: Scalars["String"];
	status: Scalars["Int"];
};

export type OrderFlowWhereInput = {
	id?: Maybe<IntFilter>;
	status?: Maybe<IntFilter>;
};

export type OrderOrderByInput = {
	createdAt?: Maybe<SortOrder>;
	code?: Maybe<SortOrder>;
};

export type OrderSnapshot = {
	__typename?: "OrderSnapshot";
	order_flow: Scalars["String"];
	notes: Scalars["String"];
	items: Array<OrderSnapshotItem>;
	fee_breakdown: OrderSnapshotFeeBreakdown;
	order_shipping?: Maybe<OrderSnapshotShipping>;
};

export type OrderSnapshotCoupon = {
	__typename?: "OrderSnapshotCoupon";
	code: Scalars["String"];
	discount_value: Scalars["Int"];
	original_price: Scalars["Int"];
};

export type OrderSnapshotDiscount = {
	__typename?: "OrderSnapshotDiscount";
	name: Scalars["String"];
	unit: Scalars["Int"];
	value: Scalars["Int"];
	price: Scalars["Int"];
};

export type OrderSnapshotFee = {
	__typename?: "OrderSnapshotFee";
	name: Scalars["String"];
	value: Scalars["Int"];
	percentage: Scalars["Int"];
};

export type OrderSnapshotFeeBreakdown = {
	__typename?: "OrderSnapshotFeeBreakdown";
	subtotal: Scalars["Int"];
	fees: Array<OrderSnapshotFee>;
	discounts: Array<OrderSnapshotDiscount>;
	taxes: Array<OrderSnapshotTax>;
};

export type OrderSnapshotItem = {
	__typename?: "OrderSnapshotItem";
	product_id: Scalars["Int"];
	name: Scalars["String"];
	price: Scalars["Int"];
	quantity: Scalars["Int"];
	modifiers?: Maybe<Array<OrderSnapshotItemModifier>>;
	coupon?: Maybe<OrderSnapshotCoupon>;
};

export type OrderSnapshotItemModifier = {
	__typename?: "OrderSnapshotItemModifier";
	cart_modifier_id: Scalars["Int"];
	modifier_id: Scalars["Int"];
	name: Scalars["String"];
	price: Scalars["Int"];
	quantity: Scalars["Int"];
};

export type OrderSnapshotShipping = {
	__typename?: "OrderSnapshotShipping";
	shipping_address_no: Scalars["String"];
	shipping_address: Scalars["String"];
	shipping_address_lat_lng: Scalars["String"];
};

export type OrderSnapshotTax = {
	__typename?: "OrderSnapshotTax";
	name: Scalars["String"];
	value: Scalars["Int"];
	percentage: Scalars["Int"];
};

export enum OrderStatus {
	New = "NEW",
	Cancelled = "CANCELLED",
	Preparing = "PREPARING",
	Ready = "READY",
	Received = "RECEIVED",
	Scheduled = "SCHEDULED",
	DeliveryPending = "DELIVERY_PENDING",
	DeliveryEnRoute = "DELIVERY_EN_ROUTE",
	DeliverySuccess = "DELIVERY_SUCCESS",
	DeliveryFailed = "DELIVERY_FAILED",
	DeliveryCancelled = "DELIVERY_CANCELLED",
}

export type OrderWhereInput = {
	id?: Maybe<IntFilter>;
	code?: Maybe<StringFilter>;
	branchId?: Maybe<IntFilter>;
};

export type PaginatedAuditLogResponse = {
	__typename?: "PaginatedAuditLogResponse";
	edges: Array<AuditLog>;
	total: Scalars["Int"];
};

export type PaginatedBranchProductResponse = {
	__typename?: "PaginatedBranchProductResponse";
	edges: Array<BranchProduct>;
	total: Scalars["Int"];
};

export type PaginatedBranchResponse = {
	__typename?: "PaginatedBranchResponse";
	edges: Array<Branch>;
	total: Scalars["Int"];
};

export type PaginatedCampaignResponse = {
	__typename?: "PaginatedCampaignResponse";
	edges: Array<Campaign>;
	total: Scalars["Int"];
};

export type PaginatedCartProductResponse = {
	__typename?: "PaginatedCartProductResponse";
	edges: Array<CartProduct>;
	total: Scalars["Int"];
};

export type PaginatedCartResponse = {
	__typename?: "PaginatedCartResponse";
	edges: Array<Cart>;
	total: Scalars["Int"];
};

export type PaginatedCouponResponse = {
	__typename?: "PaginatedCouponResponse";
	edges: Array<Coupon>;
	total: Scalars["Int"];
};

export type PaginatedFeatureToggleResponse = {
	__typename?: "PaginatedFeatureToggleResponse";
	edges: Array<FeatureToggle>;
	total: Scalars["Int"];
};

export type PaginatedFileLogResponse = {
	__typename?: "PaginatedFileLogResponse";
	edges: Array<FileLog>;
	total: Scalars["Int"];
};

export type PaginatedMembershipPartnerResponse = {
	__typename?: "PaginatedMembershipPartnerResponse";
	edges: Array<MembershipPartner>;
	total: Scalars["Int"];
};

export type PaginatedMembershipResponse = {
	__typename?: "PaginatedMembershipResponse";
	edges: Array<Membership>;
	total: Scalars["Int"];
};

export type PaginatedModifierGroupResponse = {
	__typename?: "PaginatedModifierGroupResponse";
	edges: Array<ModifierGroup>;
	total: Scalars["Int"];
};

export type PaginatedOrderFlowResponse = {
	__typename?: "PaginatedOrderFlowResponse";
	edges: Array<OrderFlow>;
	total: Scalars["Int"];
};

export type PaginatedOrderResponse = {
	__typename?: "PaginatedOrderResponse";
	edges: Array<Order>;
	total: Scalars["Int"];
};

export type PaginatedProductAddOnResponse = {
	__typename?: "PaginatedProductAddOnResponse";
	edges: Array<ProductAddOn>;
	total: Scalars["Int"];
};

export type PaginatedProductCategoryResponse = {
	__typename?: "PaginatedProductCategoryResponse";
	edges: Array<ProductCategory>;
	total: Scalars["Int"];
};

export type PaginatedProductLabelResponse = {
	__typename?: "PaginatedProductLabelResponse";
	edges: Array<ProductLabel>;
	total: Scalars["Int"];
};

export type PaginatedProductResponse = {
	__typename?: "PaginatedProductResponse";
	edges: Array<Product>;
	total: Scalars["Int"];
};

export type PaginatedPromotionCartResponse = {
	__typename?: "PaginatedPromotionCartResponse";
	edges: Array<PromotionCart>;
	total: Scalars["Int"];
};

export type PaginatedPromotionRequirementResponse = {
	__typename?: "PaginatedPromotionRequirementResponse";
	edges: Array<PromotionRequirement>;
	total: Scalars["Int"];
};

export type PaginatedPromotionResponse = {
	__typename?: "PaginatedPromotionResponse";
	edges: Array<Promotion>;
	total: Scalars["Int"];
};

export type PaginatedRoleResponse = {
	__typename?: "PaginatedRoleResponse";
	edges: Array<Role>;
	total: Scalars["Int"];
};

export type PaginatedTaxResponse = {
	__typename?: "PaginatedTaxResponse";
	edges: Array<Tax>;
	total: Scalars["Int"];
};

export type PaginatedUserResponse = {
	__typename?: "PaginatedUserResponse";
	edges: Array<User>;
	total: Scalars["Int"];
};

export enum PaymentMethod {
	Cashless = "cashless",
	Cash = "cash",
}

export type Product = {
	__typename?: "Product";
	id: Scalars["Int"];
	name: Scalars["String"];
	slug: Scalars["String"];
	price: Scalars["Int"];
	ordering: Scalars["Int"];
	categoryId?: Maybe<Scalars["Int"]>;
	image?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	cloudinaryPublicId?: Maybe<Scalars["String"]>;
	isActive: Scalars["Boolean"];
	validFrom?: Maybe<Scalars["Date"]>;
	validUntil?: Maybe<Scalars["Date"]>;
	createdAt?: Maybe<Scalars["Date"]>;
	coupon?: Maybe<ProductCoupon>;
	extraData?: Maybe<Scalars["String"]>;
	category?: Maybe<ProductCategory>;
	productAddOnIds?: Maybe<Array<Scalars["Int"]>>;
	addons?: Maybe<Array<ProductAddOn>>;
	productLabelIds?: Maybe<Array<Scalars["Int"]>>;
	productLabels?: Maybe<Array<Maybe<ProductProductLabel>>>;
	labels?: Maybe<Array<ProductLabel>>;
	branchProduct?: Maybe<BranchProduct>;
	branchProducts: Array<Maybe<BranchProduct>>;
	mealItemProducts: Array<MealItemProduct>;
	mealItemCategories: Array<MealItemCategory>;
};

export type ProductCloudinaryPublicIdArgs = {
	publicIdSegments?: Maybe<Scalars["Int"]>;
};

export type ProductBranchProductArgs = {
	branchId?: Maybe<Scalars["Int"]>;
};

export type ProductAddOn = {
	__typename?: "ProductAddOn";
	id: Scalars["Int"];
	name: Scalars["String"];
	price: Scalars["Int"];
	image?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	extraData?: Maybe<Scalars["String"]>;
};

export type ProductAddOnOrderByInput = {
	name?: Maybe<SortOrder>;
	created_at?: Maybe<SortOrder>;
};

export type ProductAddOnWhereInput = {
	id?: Maybe<IntFilter>;
};

export type ProductCategory = {
	__typename?: "ProductCategory";
	id: Scalars["Int"];
	name: Scalars["String"];
	position: Scalars["Int"];
	status: Scalars["Int"];
	description?: Maybe<Scalars["String"]>;
	validFrom: Scalars["Int"];
	validUntil: Scalars["Int"];
	extraData?: Maybe<Scalars["String"]>;
	products: Array<Product>;
	activeProducts: Array<Product>;
};

export type ProductCategoryActiveProductsArgs = {
	where?: Maybe<ProductWhereInput>;
};

export type ProductCategoryOrderByInput = {
	name?: Maybe<SortOrder>;
};

export type ProductCategoryWhereInput = {
	id?: Maybe<IntFilter>;
	name?: Maybe<StringFilter>;
	status?: Maybe<IntFilter>;
	validFrom?: Maybe<IntFilter>;
	validUntil?: Maybe<IntFilter>;
};

export type ProductCoupon = {
	__typename?: "ProductCoupon";
	code: Scalars["String"];
	discountValue: Scalars["Int"];
	quantity: Scalars["Int"];
};

export type ProductLabel = {
	__typename?: "ProductLabel";
	id: Scalars["Int"];
	name: Scalars["String"];
	products: Array<Product>;
};

export type ProductLabelOrderByInput = {
	name?: Maybe<SortOrder>;
};

export type ProductLabelWhereInput = {
	id?: Maybe<IntFilter>;
	name?: Maybe<StringFilter>;
};

export type ProductNotFound = {
	__typename?: "ProductNotFound";
	message: Scalars["String"];
};

export type ProductOrderByInput = {
	name?: Maybe<SortOrder>;
	createdAt?: Maybe<SortOrder>;
};

export type ProductProductLabel = {
	__typename?: "ProductProductLabel";
	product_id: Scalars["Int"];
	product_label_id: Scalars["Int"];
	status: Scalars["Int"];
	position: Scalars["Int"];
	productLabel: ProductLabel;
};

export type ProductResult = Product | ProductNotFound;

export type ProductWhereInput = {
	id?: Maybe<IntFilter>;
	name?: Maybe<StringFilter>;
	isActive?: Maybe<Scalars["Boolean"]>;
	validFrom?: Maybe<DateFilter>;
	validUntil?: Maybe<DateFilter>;
	branchId?: Maybe<IntFilter>;
};

export type Promotion = {
	__typename?: "Promotion";
	id: Scalars["Int"];
	cloudinaryPublicId?: Maybe<Scalars["String"]>;
	name: Scalars["String"];
	image: Scalars["String"];
	isActive: Scalars["Boolean"];
	validFrom: Scalars["Date"];
	validUntil: Scalars["Date"];
	requirements: Array<PromotionRequirement>;
	rewards: Array<PromotionReward>;
	branches: Array<Branch>;
};

export type PromotionCloudinaryPublicIdArgs = {
	publicIdSegments?: Maybe<Scalars["Int"]>;
};

export type PromotionCart = {
	__typename?: "PromotionCart";
	id: Scalars["Int"];
	cartId: Scalars["Int"];
	quantity: Scalars["Int"];
	promotionId: Scalars["Int"];
	status: PromotionCartStatus;
	cart: Cart;
	promotion: Promotion;
	promotionCartsOnProducts: Array<PromotionCartOnProducts>;
};

export type PromotionCartOnProducts = {
	__typename?: "PromotionCartOnProducts";
	promotionCartId: Scalars["Int"];
	productId: Scalars["Int"];
	quantity: Scalars["Int"];
	connectionType: PromotionToProductConnectionType;
	promotionRequirementId?: Maybe<Scalars["Int"]>;
	promotionRewardId?: Maybe<Scalars["Int"]>;
	product: Product;
};

export type PromotionCartOrderByInput = {
	id?: Maybe<SortOrder>;
};

export enum PromotionCartStatus {
	Cancelled = "CANCELLED",
	New = "NEW",
	Preparing = "PREPARING",
	Ready = "READY",
}

export type PromotionCartWhereInput = {
	id?: Maybe<IntFilter>;
	cartId?: Maybe<IntFilter>;
};

export type PromotionOrderByInput = {
	validFrom?: Maybe<SortOrder>;
	validUntil?: Maybe<SortOrder>;
	name?: Maybe<SortOrder>;
};

export type PromotionRequirement = {
	__typename?: "PromotionRequirement";
	id: Scalars["Int"];
	promotionId: Scalars["Int"];
	quantity: Scalars["Int"];
	productId?: Maybe<Scalars["Int"]>;
	categoryId?: Maybe<Scalars["Int"]>;
	product?: Maybe<Product>;
	category?: Maybe<ProductCategory>;
	promotion: Promotion;
};

export type PromotionReward = {
	__typename?: "PromotionReward";
	id: Scalars["Int"];
	promotionId: Scalars["Int"];
	productId?: Maybe<Scalars["Int"]>;
	categoryId?: Maybe<Scalars["Int"]>;
	quantity?: Maybe<Scalars["Int"]>;
	discountValue?: Maybe<Scalars["Int"]>;
	discountUnit?: Maybe<DiscountUnit>;
	product?: Maybe<Product>;
	category?: Maybe<ProductCategory>;
};

export enum PromotionToProductConnectionType {
	Requirement = "REQUIREMENT",
	Reward = "REWARD",
}

export type PromotionWhereInput = {
	id?: Maybe<IntFilter>;
	isActive?: Maybe<Scalars["Boolean"]>;
	branchId?: Maybe<IntFilter>;
	validFrom?: Maybe<DateFilter>;
	validUntil?: Maybe<DateFilter>;
};

export type Query = {
	__typename?: "Query";
	listAuditLogs: PaginatedAuditLogResponse;
	myPermissions: MyPermissionResult;
	me?: Maybe<MeResponse>;
	listBranches: PaginatedBranchResponse;
	getBranch: Branch;
	listBranchProducts: PaginatedBranchProductResponse;
	getBranchProduct?: Maybe<BranchProduct>;
	listCampaigns: PaginatedCampaignResponse;
	getCampaign: Campaign;
	listActiveCampaigns: Array<Campaign>;
	/** @deprecated Deprecated in favor of Query.myCart */
	getActiveCart?: Maybe<Cart>;
	getCart: Cart;
	listCarts: PaginatedCartResponse;
	myCart?: Maybe<Cart>;
	getActiveCartProduct: Array<CartProduct>;
	getCartProduct: CartProduct;
	listCartProducts: PaginatedCartProductResponse;
	listCategories: PaginatedProductCategoryResponse;
	/** @deprecated use listMenuSections instead */
	listActiveCategories: PaginatedProductCategoryResponse;
	getCategory?: Maybe<ProductCategory>;
	listCoupons: PaginatedCouponResponse;
	getCoupon: Coupon;
	listFeatureToggles: PaginatedFeatureToggleResponse;
	getFeatureToggle: FeatureToggle;
	listFileLogs: PaginatedFileLogResponse;
	listMembership: PaginatedMembershipResponse;
	getMembership: Membership;
	getUserMembership: GetUserMembershipResponse;
	listMembershipPartner: PaginatedMembershipPartnerResponse;
	getMembershipPartner: MembershipPartner;
	getMembershipSettings: MembershipSetting;
	listModifierGroups: PaginatedModifierGroupResponse;
	getModifierGroup: ModifierGroup;
	listOrders: PaginatedOrderResponse;
	getOrder: Order;
	historyOrders: Array<HistoryOrder>;
	listOrderFlows: PaginatedOrderFlowResponse;
	listProducts: PaginatedProductResponse;
	getProduct: ProductResult;
	listMenuSections: AllMenuSection;
	listProductAddOns: PaginatedProductAddOnResponse;
	getProductAddOn: ProductAddOn;
	listProductLabels: PaginatedProductLabelResponse;
	getProductLabel: ProductLabel;
	listPromotions: PaginatedPromotionResponse;
	getPromotion: Promotion;
	listPromotionCarts: PaginatedPromotionCartResponse;
	listPromotionRequirements: PaginatedPromotionRequirementResponse;
	listRoles: PaginatedRoleResponse;
	listTaxes: PaginatedTaxResponse;
	getTax: Tax;
	getCustomer: User;
	listCustomers: PaginatedUserResponse;
};

export type QueryListAuditLogsArgs = {
	where?: Maybe<AuditLogWhereInput>;
	orderBy?: Maybe<AuditLogOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryMyPermissionsArgs = {
	isAdmin?: Maybe<Scalars["Boolean"]>;
};

export type QueryMeArgs = {
	isAdmin?: Maybe<Scalars["Boolean"]>;
};

export type QueryListBranchesArgs = {
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
	where?: Maybe<BranchWhereInput>;
};

export type QueryGetBranchArgs = {
	id: Scalars["Int"];
};

export type QueryListBranchProductsArgs = {
	where?: Maybe<BranchProductWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetBranchProductArgs = {
	branchId: Scalars["Int"];
	productId: Scalars["Int"];
};

export type QueryListCampaignsArgs = {
	orderBy?: Maybe<CampaignOrderByInput>;
	where?: Maybe<CampaignWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetCampaignArgs = {
	id: Scalars["Int"];
};

export type QueryListActiveCampaignsArgs = {
	branchId: Scalars["Int"];
	discountLevel?: Maybe<Scalars["Int"]>;
};

export type QueryGetCartArgs = {
	id: Scalars["Int"];
};

export type QueryListCartsArgs = {
	where?: Maybe<CartWhereInput>;
	orderBy?: Maybe<CartOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetCartProductArgs = {
	id: Scalars["Int"];
};

export type QueryListCartProductsArgs = {
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
	where?: Maybe<CartProductWhereInput>;
	orderBy?: Maybe<CartProductOrderByInput>;
};

export type QueryListCategoriesArgs = {
	where?: Maybe<ProductCategoryWhereInput>;
	orderBy?: Maybe<ProductCategoryOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryListActiveCategoriesArgs = {
	where?: Maybe<ProductCategoryWhereInput>;
	orderBy?: Maybe<ProductCategoryOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetCategoryArgs = {
	id: Scalars["Int"];
};

export type QueryListCouponsArgs = {
	orderBy?: Maybe<CouponOrderByInput>;
	where?: Maybe<CouponWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetCouponArgs = {
	id: Scalars["Int"];
};

export type QueryListFeatureTogglesArgs = {
	where?: Maybe<FeatureToggleWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetFeatureToggleArgs = {
	id: Scalars["Int"];
};

export type QueryListFileLogsArgs = {
	where?: Maybe<FileLogWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryListMembershipArgs = {
	where?: Maybe<MembershipWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetMembershipArgs = {
	id: Scalars["Int"];
};

export type QueryGetUserMembershipArgs = {
	membershipNumber?: Maybe<Scalars["String"]>;
};

export type QueryListMembershipPartnerArgs = {
	where?: Maybe<MembershipPartnerWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetMembershipPartnerArgs = {
	partnerId: Scalars["Int"];
};

export type QueryListModifierGroupsArgs = {
	where?: Maybe<ModifierGroupWhereInput>;
	orderBy?: Maybe<ModifierGroupOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetModifierGroupArgs = {
	id: Scalars["Int"];
};

export type QueryListOrdersArgs = {
	where?: Maybe<OrderWhereInput>;
	orderBy?: Maybe<OrderOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetOrderArgs = {
	id: Scalars["Int"];
};

export type QueryListOrderFlowsArgs = {
	where?: Maybe<OrderFlowWhereInput>;
	orderBy?: Maybe<OrderByInputOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryListProductsArgs = {
	where?: Maybe<ProductWhereInput>;
	orderBy?: Maybe<ProductOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetProductArgs = {
	id: Scalars["Int"];
	couponCode?: Maybe<Scalars["String"]>;
	branchId?: Maybe<Scalars["Int"]>;
};

export type QueryListMenuSectionsArgs = {
	branchId: Scalars["Int"];
	couponCode?: Maybe<Scalars["String"]>;
};

export type QueryListProductAddOnsArgs = {
	where?: Maybe<ProductAddOnWhereInput>;
	orderBy?: Maybe<ProductAddOnOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetProductAddOnArgs = {
	id: Scalars["Int"];
};

export type QueryListProductLabelsArgs = {
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
	where?: Maybe<ProductLabelWhereInput>;
	orderBy?: Maybe<ProductLabelOrderByInput>;
};

export type QueryGetProductLabelArgs = {
	id: Scalars["Int"];
};

export type QueryListPromotionsArgs = {
	where?: Maybe<PromotionWhereInput>;
	orderBy?: Maybe<PromotionOrderByInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetPromotionArgs = {
	id: Scalars["Int"];
};

export type QueryListPromotionCartsArgs = {
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
	where?: Maybe<PromotionCartWhereInput>;
	orderBy?: Maybe<PromotionCartOrderByInput>;
};

export type QueryListPromotionRequirementsArgs = {
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryListTaxesArgs = {
	where?: Maybe<TaxWhereInput>;
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
};

export type QueryGetTaxArgs = {
	id: Scalars["Int"];
};

export type QueryGetCustomerArgs = {
	id: Scalars["String"];
};

export type QueryListCustomersArgs = {
	skip?: Maybe<Scalars["Int"]>;
	take?: Maybe<Scalars["Int"]>;
	where?: Maybe<UserWhereInput>;
	orderBy?: Maybe<UserOrderByInput>;
};

export type RegisterError = {
	__typename?: "RegisterError";
	message: Scalars["String"];
};

export type RegisterInput = {
	email: Scalars["String"];
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	password: Scalars["String"];
	phone: Scalars["String"];
	couponCode?: Maybe<Scalars["String"]>;
	referralCode?: Maybe<Scalars["String"]>;
};

export type RegisterResult = User | RegisterError;

export type RequirementIdProductId = {
	requirementId: Scalars["Int"];
	productId: Scalars["Int"];
};

export type ResetPasswordInput = {
	newPassword?: Maybe<Scalars["String"]>;
	tokenForgotPassword?: Maybe<Scalars["String"]>;
};

export type ResetPasswordResponse = {
	__typename?: "ResetPasswordResponse";
	message?: Maybe<Scalars["String"]>;
};

export type ResultFile = {
	__typename?: "ResultFile";
	file: File;
	status: Scalars["Int"];
};

export type RewardIdProductId = {
	rewardId: Scalars["Int"];
	productId: Scalars["Int"];
};

export type Role = {
	__typename?: "Role";
	name: Scalars["String"];
	id: Scalars["Int"];
	description?: Maybe<Scalars["String"]>;
};

export type Shipping = {
	__typename?: "Shipping";
	id: Scalars["Int"];
	shippingOrderNo?: Maybe<Scalars["String"]>;
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
};

export type Shop = {
	__typename?: "Shop";
	id: Scalars["BigInt"];
	name: Scalars["String"];
};

export enum SortOrder {
	Asc = "asc",
	Desc = "desc",
}

export type StringFilter = {
	contains?: Maybe<Scalars["String"]>;
	endsWith?: Maybe<Scalars["String"]>;
	equals?: Maybe<Scalars["String"]>;
	gt?: Maybe<Scalars["String"]>;
	gte?: Maybe<Scalars["String"]>;
	in?: Maybe<Array<Scalars["String"]>>;
	lt?: Maybe<Scalars["String"]>;
	lte?: Maybe<Scalars["String"]>;
	notIn?: Maybe<Array<Scalars["String"]>>;
	startsWith?: Maybe<Scalars["String"]>;
};

export type SubCartProduct = {
	__typename?: "SubCartProduct";
	id: Scalars["Int"];
	cartProductId: Scalars["Int"];
	productId: Scalars["Int"];
	connectionType: SubCartProductConnectionType;
	product: Product;
	mealItemCategory?: Maybe<MealItemCategory>;
};

export enum SubCartProductConnectionType {
	Choice = "CHOICE",
	Fixed = "FIXED",
}

export type Tax = {
	__typename?: "Tax";
	id: Scalars["Int"];
	name: Scalars["String"];
	percentage: Scalars["Int"];
	branches: Array<Branch>;
};

export enum TaxType {
	BeforeDiscount = "BEFORE_DISCOUNT",
	AfterDiscount = "AFTER_DISCOUNT",
}

export type TaxWhereInput = {
	id?: Maybe<IntFilter>;
	name?: Maybe<StringFilter>;
};

export type Transaction = {
	__typename?: "Transaction";
	id: Scalars["String"];
	amount: Scalars["Int"];
	status: TransactionStatus;
	mode: Scalars["String"];
	metadata?: Maybe<Scalars["JSONObject"]>;
};

export enum TransactionStatus {
	Cancelled = "cancelled",
	Failed = "failed",
	New = "new",
	Success = "success",
}

export type UpdateBranchInput = {
	address?: Maybe<Scalars["String"]>;
	name?: Maybe<Scalars["String"]>;
	postalCode?: Maybe<Scalars["String"]>;
	timezone?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	countryCode?: Maybe<Scalars["String"]>;
	longitude?: Maybe<Scalars["String"]>;
	latitude?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	region?: Maybe<Scalars["String"]>;
	googleMapLink?: Maybe<Scalars["String"]>;
	taxType?: Maybe<TaxType>;
	operatingTimes?: Maybe<Array<CreateOperatingTimeInput>>;
};

export type UpdateBranchProductInput = {
	branchId: Scalars["Int"];
	position: Scalars["Int"];
	price: Scalars["Int"];
	status: Scalars["Int"];
	validFrom: Scalars["Int"];
	validUntil?: Maybe<Scalars["Int"]>;
	orderFlowIds?: Maybe<Array<Scalars["Int"]>>;
	modifierGroupIds?: Maybe<Array<Scalars["Int"]>>;
};

export type UpdateCampaignBranchInput = {
	branchId: Scalars["Int"];
	status: Scalars["Int"];
};

export type UpdateCampaignInput = {
	id: Scalars["Int"];
	name: Scalars["String"];
	description: Scalars["String"];
	campaignBranches: Array<UpdateCampaignBranchInput>;
	discountUnit: Scalars["Int"];
	discountValue: Scalars["Int"];
	status: Scalars["Int"];
	startAt: Scalars["Int"];
	endAt: Scalars["Int"];
};

export type UpdateCartProductInput = {
	quantity?: Maybe<Scalars["Int"]>;
	status?: Maybe<CartProductStatus>;
	productAddOnIds?: Maybe<Array<Scalars["Int"]>>;
	subProducts?: Maybe<Array<UpdateSubCartProductInput>>;
};

export type UpdateCategoryInput = {
	name?: Maybe<Scalars["String"]>;
	position?: Maybe<Scalars["Int"]>;
	status?: Maybe<Scalars["Int"]>;
	validFrom?: Maybe<Scalars["Int"]>;
	validUntil?: Maybe<Scalars["Int"]>;
	extraData?: Maybe<Scalars["String"]>;
};

export type UpdateCouponInput = {
	id: Scalars["Int"];
	status?: Maybe<Scalars["Int"]>;
	maxQuota?: Maybe<Scalars["Int"]>;
	description?: Maybe<Scalars["String"]>;
	userEmail?: Maybe<Scalars["String"]>;
	userPhone?: Maybe<Scalars["String"]>;
	userName?: Maybe<Scalars["String"]>;
	couponBranchProducts: Array<UpdateCouponInputBranchProducts>;
};

export type UpdateCouponInputBranchProducts = {
	branchProductId: Scalars["Int"];
	quantity: Scalars["Int"];
	discountValue: Scalars["Int"];
	status: Scalars["Int"];
};

export type UpdateMembershipInput = {
	id: Scalars["Int"];
	status: Scalars["Int"];
};

export type UpdateMembershipPartnerInput = {
	partnerId: Scalars["Int"];
	name?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	status?: Maybe<Scalars["Int"]>;
	discounts?: Maybe<Array<MembershipDiscountInput>>;
	inherit_discounts_from_global_settings?: Maybe<Scalars["Boolean"]>;
};

export type UpdateModifierGroupInput = {
	branchId: Scalars["Int"];
	name: Scalars["String"];
	description: Scalars["String"];
	minQuantity: Scalars["Int"];
	maxQuantity: Scalars["Int"];
	type: Scalars["Int"];
	status: Scalars["Int"];
	position: Scalars["Int"];
	modifiers?: Maybe<Array<CreateOrUpdateModifierInput>>;
};

export type UpdateModifierInput = {
	id: Scalars["Int"];
	price: Scalars["Int"];
	ref: Scalars["Int"];
	status: Scalars["Int"];
};

export type UpdateOrderInput = {
	status?: Maybe<OrderStatus>;
	transaction?: Maybe<UpdateTransactionInput>;
};

export type UpdateProductAddOnInput = {
	name?: Maybe<Scalars["String"]>;
	price?: Maybe<Scalars["Int"]>;
	description?: Maybe<Scalars["String"]>;
	image?: Maybe<Scalars["String"]>;
};

export type UpdateProductInput = {
	name?: Maybe<Scalars["String"]>;
	image?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	imageUpload?: Maybe<Scalars["Upload"]>;
	categoryId?: Maybe<Scalars["Int"]>;
	productAddOnIds?: Maybe<Array<Scalars["Int"]>>;
	productLabels?: Maybe<Array<UpdateProductProductLabelInput>>;
	mealItemProducts?: Maybe<Array<CreateMealItemProduct>>;
	mealItemCategories?: Maybe<Array<CreateMealItemCategory>>;
	branchProducts?: Maybe<Array<UpdateBranchProductInput>>;
};

export type UpdateProductLabelInput = {
	name?: Maybe<Scalars["String"]>;
};

export type UpdateProductProductLabelInput = {
	product_label_id: Scalars["Int"];
	status: Scalars["Int"];
};

export type UpdateProfileInput = {
	firstName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
};

export type UpdatePromotionCartInput = {
	requirementIdProductIds?: Maybe<Array<RequirementIdProductId>>;
	rewardIdProductIds?: Maybe<Array<RewardIdProductId>>;
	quantity?: Maybe<Scalars["Int"]>;
	status?: Maybe<PromotionCartStatus>;
};

export type UpdatePromotionInput = {
	name?: Maybe<Scalars["String"]>;
	validFrom?: Maybe<Scalars["Date"]>;
	validUntil?: Maybe<Scalars["Date"]>;
	imageUpload?: Maybe<Scalars["Upload"]>;
	requirements?: Maybe<Array<CreatePromotionRequirementInput>>;
	rewards?: Maybe<Array<CreatePromotionRewardInput>>;
	branchIds?: Maybe<Array<Scalars["Int"]>>;
};

export type UpdateSubCartProductInput = {
	id: Scalars["Int"];
	productId: Scalars["Int"];
};

export type UpdateTaxInput = {
	name?: Maybe<Scalars["String"]>;
	percentage?: Maybe<Scalars["Int"]>;
	branchIds?: Maybe<Array<Scalars["Int"]>>;
};

export type UpdateTransactionInput = {
	status?: Maybe<TransactionStatus>;
};

export type User = {
	__typename?: "User";
	id: Scalars["String"];
	email?: Maybe<Scalars["String"]>;
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	countryCode?: Maybe<Scalars["String"]>;
	lastSignInAt: Scalars["DateTime"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
};

export type UserOrderByInput = {
	email?: Maybe<SortOrder>;
};

export type UserWhereInput = {
	id?: Maybe<StringFilter>;
	email?: Maybe<StringFilter>;
};

export type ValidateCouponInput = {
	couponCode: Scalars["String"];
	branchId: Scalars["Int"];
};

export type ValidateCouponResponse = {
	__typename?: "validateCouponResponse";
	error: Scalars["String"];
	coupon?: Maybe<CouponWithCampaign>;
};

export type GetUserMembershipQueryVariables = Exact<{
	membershipNumber?: Maybe<Scalars["String"]>;
}>;

export type GetUserMembershipQuery = { __typename?: "Query" } & {
	getUserMembership: { __typename?: "GetUserMembershipResponse" } & Pick<
		GetUserMembershipResponse,
		| "qr"
		| "membershipNumber"
		| "membershipPartnerName"
		| "referralFrom"
		| "isReferralFromUsable"
		| "referralFromUnusableReason"
		| "referralCode"
		| "isReferralUsable"
		| "referralUnusableReason"
		| "isActive"
	> & {
			discounts: Array<
				{ __typename?: "MembershipDiscount" } & Pick<
					MembershipDiscount,
					"name" | "unit" | "value" | "isReferral"
				>
			>;
			referralDiscount?: Maybe<
				{ __typename?: "MembershipDiscount" } & Pick<
					MembershipDiscount,
					"name" | "unit" | "value" | "isReferral"
				>
			>;
		};
};

export type LoginMutationVariables = Exact<{
	input: LoginInput;
}>;

export type LoginMutation = { __typename?: "Mutation" } & {
	login: { __typename?: "LoginResponse" } & Pick<LoginResponse, "message"> & {
			user?: Maybe<{ __typename?: "User" } & Pick<User, "email">>;
		};
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"logout"
>;

export type RegisterMutationVariables = Exact<{
	input: RegisterInput;
}>;

export type RegisterMutation = { __typename?: "Mutation" } & {
	register:
		| ({ __typename?: "User" } & Pick<User, "email">)
		| ({ __typename?: "RegisterError" } & Pick<RegisterError, "message">);
};

export type ForgotPasswordMutationVariables = Exact<{
	input?: Maybe<ForgotPasswordInput>;
}>;

export type ForgotPasswordMutation = { __typename?: "Mutation" } & {
	forgotPassword: { __typename?: "ForgotPasswordResponse" } & Pick<
		ForgotPasswordResponse,
		"success" | "message"
	>;
};

export type IsForgotPasswordTokenValidMutationVariables = Exact<{
	input?: Maybe<IsForgotPasswordTokenValidInput>;
}>;

export type IsForgotPasswordTokenValidMutation = { __typename?: "Mutation" } & {
	isForgotPasswordTokenValid: {
		__typename?: "IsForgotPasswordTokenValidResponse";
	} & Pick<IsForgotPasswordTokenValidResponse, "success" | "message">;
};

export type ResetPasswordMutationVariables = Exact<{
	input?: Maybe<ResetPasswordInput>;
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & {
	resetPassword: { __typename?: "ResetPasswordResponse" } & Pick<
		ResetPasswordResponse,
		"message"
	>;
};

export type UpdateProfileMutationVariables = Exact<{
	values: UpdateProfileInput;
}>;

export type UpdateProfileMutation = { __typename?: "Mutation" } & {
	updateProfile: { __typename?: "User" } & Pick<User, "email">;
};

export type CreateConfirmationMutationVariables = Exact<{
	input: CreateConfirmationInput;
}>;

export type CreateConfirmationMutation = { __typename?: "Mutation" } & {
	createConfirmation: { __typename?: "CreateConfirmationResponse" } & Pick<
		CreateConfirmationResponse,
		"success" | "message" | "phone" | "email"
	>;
};

export type ConfirmPhoneMutationVariables = Exact<{
	otp: Scalars["String"];
}>;

export type ConfirmPhoneMutation = { __typename?: "Mutation" } & {
	confirmPhone: { __typename?: "ConfirmPhoneResponse" } & Pick<
		ConfirmPhoneResponse,
		"success" | "message"
	>;
};

export type ConfirmEmailMutationVariables = Exact<{
	token: Scalars["String"];
}>;

export type ConfirmEmailMutation = { __typename?: "Mutation" } & {
	confirmEmail: { __typename?: "ConfirmEmailResponse" } & Pick<
		ConfirmEmailResponse,
		"success" | "message"
	>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: "Query" } & {
	me?: Maybe<
		{ __typename?: "MeResponse" } & Pick<
			MeResponse,
			| "id"
			| "firstName"
			| "lastName"
			| "email"
			| "countryCode"
			| "phone"
			| "couponCode"
		>
	>;
};

export type ListBranchesQueryVariables = Exact<{
	where?: Maybe<BranchWhereInput>;
}>;

export type ListBranchesQuery = { __typename?: "Query" } & {
	listBranches: { __typename?: "PaginatedBranchResponse" } & {
		edges: Array<
			{ __typename?: "Branch" } & Pick<
				Branch,
				"id" | "name" | "address" | "timezone"
			> & {
					operatingTimes: Array<
						{ __typename?: "OperatingTime" } & Pick<
							OperatingTime,
							"id" | "closeTime" | "openTime" | "dayOfWeek"
						>
					>;
					taxes: Array<
						{ __typename?: "Tax" } & Pick<Tax, "id" | "name" | "percentage">
					>;
				}
		>;
	};
};

export type ListBranchesNameQueryVariables = Exact<{ [key: string]: never }>;

export type ListBranchesNameQuery = { __typename?: "Query" } & {
	listBranches: { __typename?: "PaginatedBranchResponse" } & {
		edges: Array<
			{ __typename?: "Branch" } & Pick<Branch, "id" | "name" | "address">
		>;
	};
};

export type GetBranchQueryVariables = Exact<{
	id: Scalars["Int"];
}>;

export type GetBranchQuery = { __typename?: "Query" } & {
	getBranch: { __typename?: "Branch" } & Pick<
		Branch,
		"id" | "name" | "address" | "latitude" | "longitude"
	>;
};

export type ListActiveCampaignsQueryVariables = Exact<{
	branchId: Scalars["Int"];
	discountLevel?: Maybe<Scalars["Int"]>;
}>;

export type ListActiveCampaignsQuery = { __typename?: "Query" } & {
	listActiveCampaigns: Array<
		{ __typename?: "Campaign" } & Pick<
			Campaign,
			"id" | "name" | "description" | "startAt" | "endAt"
		>
	>;
};

export type CartProductIdFragment = { __typename?: "CartProduct" } & Pick<
	CartProduct,
	"productId" | "cartId"
>;

export type DeleteCartProductMutationVariables = Exact<{
	id: Scalars["Int"];
}>;

export type DeleteCartProductMutation = { __typename?: "Mutation" } & {
	deleteCartProduct: { __typename?: "CartProduct" } & Pick<CartProduct, "id">;
};

export type UpdateCartProductMutationVariables = Exact<{
	id: Scalars["Int"];
	values: UpdateCartProductInput;
}>;

export type UpdateCartProductMutation = { __typename?: "Mutation" } & {
	updateCartProduct: { __typename?: "CartProduct" } & Pick<
		CartProduct,
		"id" | "cartId" | "productId" | "quantity" | "status"
	> & {
			addons: Array<
				{ __typename?: "CartProductProductAddOn" } & Pick<
					CartProductProductAddOn,
					"cartProductId" | "productAddOnId"
				> & {
						productAddOn: { __typename?: "ProductAddOn" } & Pick<
							ProductAddOn,
							"id" | "name" | "price"
						>;
					}
			>;
			product: { __typename?: "Product" } & Pick<
				Product,
				"id" | "name" | "image" | "price"
			> & {
					addons?: Maybe<
						Array<
							{ __typename?: "ProductAddOn" } & Pick<
								ProductAddOn,
								"id" | "name" | "price"
							>
						>
					>;
				};
		};
};

export type AddProductToCartWithAddOnsMutationVariables = Exact<{
	productId: Scalars["Int"];
	branchId: Scalars["Int"];
	orderFlowId: Scalars["Int"];
	name: Scalars["String"];
	price: Scalars["Int"];
	quantity?: Maybe<Scalars["Int"]>;
	productAddOnIds: Array<Scalars["Int"]> | Scalars["Int"];
	subProducts:
		| Array<CreateSubCartProductWithoutCartProductInput>
		| CreateSubCartProductWithoutCartProductInput;
	coupon?: Maybe<AddProductToCartCoupon>;
}>;

export type AddProductToCartWithAddOnsMutation = { __typename?: "Mutation" } & {
	addProductToCartWithAddOns: { __typename?: "CartProduct" } & Pick<
		CartProduct,
		"id" | "cartId" | "productId" | "quantity" | "status"
	> & {
			product: { __typename?: "Product" } & Pick<
				Product,
				"id" | "name" | "image" | "price"
			>;
		};
};

export type GetActiveCartProductQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetActiveCartProductQuery = { __typename?: "Query" } & {
	getActiveCartProduct: Array<
		{ __typename?: "CartProduct" } & Pick<CartProduct, "id" | "quantity"> & {
				product: { __typename?: "Product" } & Pick<
					Product,
					"id" | "name" | "slug" | "price" | "image" | "cloudinaryPublicId"
				> & {
						addons?: Maybe<
							Array<
								{ __typename?: "ProductAddOn" } & Pick<
									ProductAddOn,
									"id" | "name" | "price"
								>
							>
						>;
					};
				addons: Array<
					{ __typename?: "CartProductProductAddOn" } & Pick<
						CartProductProductAddOn,
						"cartProductId" | "productAddOnId"
					> & {
							productAddOn: { __typename?: "ProductAddOn" } & Pick<
								ProductAddOn,
								"id" | "name" | "price"
							>;
						}
				>;
			} & CartProductIdFragment
	>;
};

export type GetActiveCartProductBaseQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetActiveCartProductBaseQuery = { __typename?: "Query" } & {
	getActiveCartProduct: Array<
		{ __typename?: "CartProduct" } & Pick<CartProduct, "quantity"> &
			CartProductIdFragment
	>;
};

export type ClearCartMutationVariables = Exact<{ [key: string]: never }>;

export type ClearCartMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"clearCart"
>;

export type DeleteAndClearCartMutationVariables = Exact<{
	[key: string]: never;
}>;

export type DeleteAndClearCartMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"deleteAndClearCart"
>;

export type MyCartQueryVariables = Exact<{ [key: string]: never }>;

export type MyCartQuery = { __typename?: "Query" } & {
	myCart?: Maybe<{ __typename?: "Cart" } & Pick<Cart, "id">>;
};

export type MyCartTotalItemsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCartTotalItemsQuery = { __typename?: "Query" } & {
	myCart?: Maybe<{ __typename?: "Cart" } & Pick<Cart, "id" | "totalItems">>;
};

export type MyCartItemsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCartItemsQuery = { __typename?: "Query" } & {
	myCart?: Maybe<
		{ __typename?: "Cart" } & Pick<Cart, "id" | "totalProductsPrice"> & {
				promotionCarts: Array<
					{ __typename?: "PromotionCart" } & Pick<
						PromotionCart,
						"id" | "quantity"
					> & {
							promotion: { __typename?: "Promotion" } & Pick<
								Promotion,
								"id" | "name" | "cloudinaryPublicId"
							> & {
									rewards: Array<
										{ __typename?: "PromotionReward" } & Pick<
											PromotionReward,
											"discountUnit" | "discountValue"
										>
									>;
								};
							promotionCartsOnProducts: Array<
								{ __typename?: "PromotionCartOnProducts" } & Pick<
									PromotionCartOnProducts,
									| "quantity"
									| "connectionType"
									| "promotionRequirementId"
									| "promotionRewardId"
								> & {
										product: { __typename?: "Product" } & Pick<
											Product,
											"id" | "name" | "price"
										>;
									}
							>;
						}
				>;
				productConnection: Array<
					{ __typename?: "CartProduct" } & Pick<
						CartProduct,
						"id" | "quantity" | "extraData" | "price"
					> & {
							product: { __typename?: "Product" } & Pick<
								Product,
								| "id"
								| "name"
								| "slug"
								| "price"
								| "image"
								| "cloudinaryPublicId"
							> & {
									addons?: Maybe<
										Array<
											{ __typename?: "ProductAddOn" } & Pick<
												ProductAddOn,
												"id" | "name" | "price"
											>
										>
									>;
								};
							addons: Array<
								{ __typename?: "CartProductProductAddOn" } & Pick<
									CartProductProductAddOn,
									"cartProductId" | "productAddOnId"
								> & {
										productAddOn: { __typename?: "ProductAddOn" } & Pick<
											ProductAddOn,
											"id" | "name" | "price"
										>;
									}
							>;
							subCartProducts: Array<
								{ __typename?: "SubCartProduct" } & Pick<
									SubCartProduct,
									"id"
								> & {
										product: { __typename?: "Product" } & Pick<
											Product,
											"id" | "name"
										>;
										mealItemCategory?: Maybe<
											{ __typename?: "MealItemCategory" } & Pick<
												MealItemCategory,
												"id" | "quantity"
											> & {
													category: { __typename?: "ProductCategory" } & Pick<
														ProductCategory,
														"id" | "name"
													>;
												}
										>;
									}
							>;
						}
				>;
			}
	>;
};

export type MyCartItemIdsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCartItemIdsQuery = { __typename?: "Query" } & {
	myCart?: Maybe<
		{ __typename?: "Cart" } & {
			promotionCarts: Array<
				{ __typename?: "PromotionCart" } & Pick<
					PromotionCart,
					"promotionId" | "quantity"
				>
			>;
			productConnection: Array<
				{ __typename?: "CartProduct" } & Pick<
					CartProduct,
					"quantity" | "productId" | "extraData"
				>
			>;
		}
	>;
};

export type ListCategoriesWithProductsQueryVariables = Exact<{
	[key: string]: never;
}>;

export type ListCategoriesWithProductsQuery = { __typename?: "Query" } & {
	listCategories: { __typename?: "PaginatedProductCategoryResponse" } & {
		edges: Array<
			{ __typename?: "ProductCategory" } & Pick<
				ProductCategory,
				"id" | "name"
			> & {
					products: Array<
						{ __typename?: "Product" } & Pick<Product, "id" | "name" | "price">
					>;
				}
		>;
	};
};

export type ValidateCouponMutationVariables = Exact<{
	input: ValidateCouponInput;
}>;

export type ValidateCouponMutation = { __typename?: "Mutation" } & {
	validateCoupon: { __typename?: "validateCouponResponse" } & Pick<
		ValidateCouponResponse,
		"error"
	> & {
			coupon?: Maybe<
				{ __typename?: "CouponWithCampaign" } & Pick<
					CouponWithCampaign,
					"code" | "userName" | "discountLevel"
				>
			>;
		};
};

export type OrderHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type OrderHistoryQuery = { __typename?: "Query" } & {
	historyOrders: Array<
		{ __typename?: "HistoryOrder" } & Pick<
			HistoryOrder,
			"id" | "code" | "status" | "createdAt" | "scheduledAt"
		> & {
				branch: { __typename?: "Branch" } & Pick<Branch, "name">;
				snapshot?: Maybe<
					{ __typename?: "OrderSnapshot" } & Pick<
						OrderSnapshot,
						"order_flow" | "notes"
					> & {
							fee_breakdown: {
								__typename?: "OrderSnapshotFeeBreakdown";
							} & Pick<OrderSnapshotFeeBreakdown, "subtotal"> & {
									fees: Array<
										{ __typename?: "OrderSnapshotFee" } & Pick<
											OrderSnapshotFee,
											"name" | "value" | "percentage"
										>
									>;
									discounts: Array<
										{ __typename?: "OrderSnapshotDiscount" } & Pick<
											OrderSnapshotDiscount,
											"name" | "value" | "unit" | "price"
										>
									>;
									taxes: Array<
										{ __typename?: "OrderSnapshotTax" } & Pick<
											OrderSnapshotTax,
											"name" | "value" | "percentage"
										>
									>;
								};
							items: Array<
								{ __typename?: "OrderSnapshotItem" } & Pick<
									OrderSnapshotItem,
									"product_id" | "name" | "quantity" | "price"
								> & {
										modifiers?: Maybe<
											Array<
												{ __typename?: "OrderSnapshotItemModifier" } & Pick<
													OrderSnapshotItemModifier,
													| "cart_modifier_id"
													| "modifier_id"
													| "name"
													| "price"
													| "quantity"
												>
											>
										>;
										coupon?: Maybe<
											{ __typename?: "OrderSnapshotCoupon" } & Pick<
												OrderSnapshotCoupon,
												"code" | "discount_value" | "original_price"
											>
										>;
									}
							>;
						}
				>;
			}
	>;
};

export type CheckoutMutationVariables = Exact<{
	input: CheckoutInput;
}>;

export type CheckoutMutation = { __typename?: "Mutation" } & {
	checkout: { __typename?: "CheckoutResponse" } & Pick<
		CheckoutResponse,
		"invoiceUrl"
	> & { order: { __typename?: "Order" } & Pick<Order, "code"> };
};

export type ListProductsQueryVariables = Exact<{
	take: Scalars["Int"];
}>;

export type ListProductsQuery = { __typename?: "Query" } & {
	listProducts: { __typename?: "PaginatedProductResponse" } & {
		edges: Array<
			{ __typename?: "Product" } & Pick<
				Product,
				| "id"
				| "name"
				| "slug"
				| "description"
				| "image"
				| "cloudinaryPublicId"
				| "price"
			> & {
					category?: Maybe<
						{ __typename?: "ProductCategory" } & Pick<
							ProductCategory,
							"id" | "name"
						>
					>;
					labels?: Maybe<
						Array<
							{ __typename?: "ProductLabel" } & Pick<
								ProductLabel,
								"id" | "name"
							>
						>
					>;
				}
		>;
	};
};

export type GetProductQueryVariables = Exact<{
	id: Scalars["Int"];
	branchId: Scalars["Int"];
	couponCode?: Maybe<Scalars["String"]>;
}>;

export type GetProductQuery = { __typename?: "Query" } & {
	getProduct:
		| ({ __typename?: "Product" } & Pick<
				Product,
				| "id"
				| "name"
				| "slug"
				| "description"
				| "image"
				| "cloudinaryPublicId"
				| "price"
		  > & {
					addons?: Maybe<
						Array<
							{ __typename?: "ProductAddOn" } & Pick<
								ProductAddOn,
								"id" | "name" | "price"
							>
						>
					>;
					labels?: Maybe<
						Array<
							{ __typename?: "ProductLabel" } & Pick<
								ProductLabel,
								"id" | "name"
							>
						>
					>;
					mealItemProducts: Array<
						{ __typename?: "MealItemProduct" } & Pick<
							MealItemProduct,
							"quantity"
						> & {
								childProduct: { __typename?: "Product" } & Pick<
									Product,
									"id" | "name"
								>;
							}
					>;
					coupon?: Maybe<
						{ __typename?: "ProductCoupon" } & Pick<
							ProductCoupon,
							"code" | "discountValue" | "quantity"
						>
					>;
					mealItemCategories: Array<
						{ __typename?: "MealItemCategory" } & Pick<
							MealItemCategory,
							"id" | "quantity"
						> & {
								category: { __typename?: "ProductCategory" } & Pick<
									ProductCategory,
									"id" | "name"
								>;
							}
					>;
				})
		| { __typename?: "ProductNotFound" };
};

export type ListMenuSectionsQueryVariables = Exact<{
	branchId: Scalars["Int"];
	couponCode?: Maybe<Scalars["String"]>;
}>;

export type ListMenuSectionsQuery = { __typename?: "Query" } & {
	listMenuSections: { __typename?: "AllMenuSection" } & {
		special: Array<
			{ __typename?: "MenuSection" } & Pick<
				MenuSection,
				"id" | "name" | "position"
			> & {
					items?: Maybe<
						Array<
							{ __typename?: "MenuSectionItem" } & Pick<
								MenuSectionItem,
								| "id"
								| "name"
								| "image"
								| "cloudinaryPublicId"
								| "slug"
								| "description"
								| "position"
								| "price"
							> & {
									labels?: Maybe<
										Array<
											{ __typename?: "MenuSectionItemLabel" } & Pick<
												MenuSectionItemLabel,
												"id" | "name" | "position"
											>
										>
									>;
									coupon?: Maybe<
										{ __typename?: "MealItemCoupon" } & Pick<
											MealItemCoupon,
											"code" | "discountValue"
										>
									>;
								}
						>
					>;
				}
		>;
		main: Array<
			{ __typename?: "MenuSection" } & Pick<
				MenuSection,
				"id" | "name" | "position"
			> & {
					items?: Maybe<
						Array<
							{ __typename?: "MenuSectionItem" } & Pick<
								MenuSectionItem,
								| "id"
								| "name"
								| "image"
								| "cloudinaryPublicId"
								| "slug"
								| "description"
								| "position"
								| "price"
							> & {
									labels?: Maybe<
										Array<
											{ __typename?: "MenuSectionItemLabel" } & Pick<
												MenuSectionItemLabel,
												"id" | "name" | "position"
											>
										>
									>;
								}
						>
					>;
				}
		>;
	};
};

export type AddPromotionToCartMutationVariables = Exact<{
	values: AddPromotionToCartInput;
}>;

export type AddPromotionToCartMutation = { __typename?: "Mutation" } & {
	addPromotionToCart: { __typename?: "PromotionCart" } & Pick<
		PromotionCart,
		"id"
	>;
};

export type UpdatePromotionCartMutationVariables = Exact<{
	id: Scalars["Int"];
	values: UpdatePromotionCartInput;
}>;

export type UpdatePromotionCartMutation = { __typename?: "Mutation" } & {
	updatePromotionCart: { __typename?: "PromotionCart" } & Pick<
		PromotionCart,
		"id" | "quantity"
	>;
};

export type DeletePromotionCartMutationVariables = Exact<{
	id: Scalars["Int"];
}>;

export type DeletePromotionCartMutation = { __typename?: "Mutation" } & {
	deletePromotionCart: { __typename?: "PromotionCart" } & Pick<
		PromotionCart,
		"id"
	>;
};

export type ListPromotionsQueryVariables = Exact<{
	where?: Maybe<PromotionWhereInput>;
}>;

export type ListPromotionsQuery = { __typename?: "Query" } & {
	listPromotions: { __typename?: "PaginatedPromotionResponse" } & {
		edges: Array<
			{ __typename?: "Promotion" } & Pick<
				Promotion,
				| "id"
				| "name"
				| "validFrom"
				| "validUntil"
				| "image"
				| "cloudinaryPublicId"
			>
		>;
	};
};

export type GetPromotionQueryVariables = Exact<{
	id: Scalars["Int"];
}>;

export type GetPromotionQuery = { __typename?: "Query" } & {
	getPromotion: { __typename?: "Promotion" } & Pick<
		Promotion,
		"id" | "name" | "validFrom" | "validUntil" | "image" | "cloudinaryPublicId"
	> & {
			requirements: Array<
				{ __typename?: "PromotionRequirement" } & Pick<
					PromotionRequirement,
					"id" | "quantity" | "productId" | "categoryId"
				> & {
						product?: Maybe<{ __typename?: "Product" } & Pick<Product, "name">>;
						category?: Maybe<
							{ __typename?: "ProductCategory" } & Pick<ProductCategory, "name">
						>;
					}
			>;
			rewards: Array<
				{ __typename?: "PromotionReward" } & Pick<
					PromotionReward,
					| "id"
					| "quantity"
					| "productId"
					| "categoryId"
					| "discountUnit"
					| "discountValue"
				> & {
						product?: Maybe<
							{ __typename?: "Product" } & Pick<Product, "id" | "name">
						>;
						category?: Maybe<
							{ __typename?: "ProductCategory" } & Pick<
								ProductCategory,
								"id" | "name"
							>
						>;
					}
			>;
		};
};

export type ListTaxesQueryVariables = Exact<{ [key: string]: never }>;

export type ListTaxesQuery = { __typename?: "Query" } & {
	listTaxes: { __typename?: "PaginatedTaxResponse" } & {
		edges: Array<
			{ __typename?: "Tax" } & Pick<Tax, "name" | "percentage" | "id">
		>;
	};
};

export const CartProductIdFragmentDoc = `
    fragment CartProductID on CartProduct {
  productId
  cartId
}
    `;
export const GetUserMembershipDocument = `
    query GetUserMembership($membershipNumber: String) {
  getUserMembership(membershipNumber: $membershipNumber) {
    qr
    membershipNumber
    membershipPartnerName
    referralFrom
    isReferralFromUsable
    referralFromUnusableReason
    referralCode
    isReferralUsable
    referralUnusableReason
    isActive
    discounts {
      name
      unit
      value
      isReferral
    }
    referralDiscount {
      name
      unit
      value
      isReferral
    }
  }
}
    `;
export const useGetUserMembershipQuery = <
	TData = GetUserMembershipQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: GetUserMembershipQueryVariables,
	options?: UseQueryOptions<GetUserMembershipQuery, TError, TData>
) =>
	useQuery<GetUserMembershipQuery, TError, TData>(
		["GetUserMembership", variables],
		fetcher<GetUserMembershipQuery, GetUserMembershipQueryVariables>(
			client,
			GetUserMembershipDocument,
			variables
		),
		options
	);
export const LoginDocument = `
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    user {
      email
    }
    message
  }
}
    `;
export const useLoginMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		LoginMutation,
		TError,
		LoginMutationVariables,
		TContext
	>
) =>
	useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
		(variables?: LoginMutationVariables) =>
			fetcher<LoginMutation, LoginMutationVariables>(
				client,
				LoginDocument,
				variables
			)(),
		options
	);
export const LogoutDocument = `
    mutation Logout {
  logout
}
    `;
export const useLogoutMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		LogoutMutation,
		TError,
		LogoutMutationVariables,
		TContext
	>
) =>
	useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
		(variables?: LogoutMutationVariables) =>
			fetcher<LogoutMutation, LogoutMutationVariables>(
				client,
				LogoutDocument,
				variables
			)(),
		options
	);
export const RegisterDocument = `
    mutation Register($input: RegisterInput!) {
  register(input: $input) {
    ... on User {
      email
    }
    ... on RegisterError {
      message
    }
  }
}
    `;
export const useRegisterMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		RegisterMutation,
		TError,
		RegisterMutationVariables,
		TContext
	>
) =>
	useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>(
		(variables?: RegisterMutationVariables) =>
			fetcher<RegisterMutation, RegisterMutationVariables>(
				client,
				RegisterDocument,
				variables
			)(),
		options
	);
export const ForgotPasswordDocument = `
    mutation ForgotPassword($input: ForgotPasswordInput) {
  forgotPassword(input: $input) {
    success
    message
  }
}
    `;
export const useForgotPasswordMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		ForgotPasswordMutation,
		TError,
		ForgotPasswordMutationVariables,
		TContext
	>
) =>
	useMutation<
		ForgotPasswordMutation,
		TError,
		ForgotPasswordMutationVariables,
		TContext
	>(
		(variables?: ForgotPasswordMutationVariables) =>
			fetcher<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
				client,
				ForgotPasswordDocument,
				variables
			)(),
		options
	);
export const IsForgotPasswordTokenValidDocument = `
    mutation IsForgotPasswordTokenValid($input: IsForgotPasswordTokenValidInput) {
  isForgotPasswordTokenValid(input: $input) {
    success
    message
  }
}
    `;
export const useIsForgotPasswordTokenValidMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		IsForgotPasswordTokenValidMutation,
		TError,
		IsForgotPasswordTokenValidMutationVariables,
		TContext
	>
) =>
	useMutation<
		IsForgotPasswordTokenValidMutation,
		TError,
		IsForgotPasswordTokenValidMutationVariables,
		TContext
	>(
		(variables?: IsForgotPasswordTokenValidMutationVariables) =>
			fetcher<
				IsForgotPasswordTokenValidMutation,
				IsForgotPasswordTokenValidMutationVariables
			>(client, IsForgotPasswordTokenValidDocument, variables)(),
		options
	);
export const ResetPasswordDocument = `
    mutation ResetPassword($input: ResetPasswordInput) {
  resetPassword(input: $input) {
    message
  }
}
    `;
export const useResetPasswordMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		ResetPasswordMutation,
		TError,
		ResetPasswordMutationVariables,
		TContext
	>
) =>
	useMutation<
		ResetPasswordMutation,
		TError,
		ResetPasswordMutationVariables,
		TContext
	>(
		(variables?: ResetPasswordMutationVariables) =>
			fetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(
				client,
				ResetPasswordDocument,
				variables
			)(),
		options
	);
export const UpdateProfileDocument = `
    mutation UpdateProfile($values: UpdateProfileInput!) {
  updateProfile(values: $values) {
    email
  }
}
    `;
export const useUpdateProfileMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		UpdateProfileMutation,
		TError,
		UpdateProfileMutationVariables,
		TContext
	>
) =>
	useMutation<
		UpdateProfileMutation,
		TError,
		UpdateProfileMutationVariables,
		TContext
	>(
		(variables?: UpdateProfileMutationVariables) =>
			fetcher<UpdateProfileMutation, UpdateProfileMutationVariables>(
				client,
				UpdateProfileDocument,
				variables
			)(),
		options
	);
export const CreateConfirmationDocument = `
    mutation CreateConfirmation($input: CreateConfirmationInput!) {
  createConfirmation(input: $input) {
    success
    message
    phone
    email
  }
}
    `;
export const useCreateConfirmationMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		CreateConfirmationMutation,
		TError,
		CreateConfirmationMutationVariables,
		TContext
	>
) =>
	useMutation<
		CreateConfirmationMutation,
		TError,
		CreateConfirmationMutationVariables,
		TContext
	>(
		(variables?: CreateConfirmationMutationVariables) =>
			fetcher<CreateConfirmationMutation, CreateConfirmationMutationVariables>(
				client,
				CreateConfirmationDocument,
				variables
			)(),
		options
	);
export const ConfirmPhoneDocument = `
    mutation ConfirmPhone($otp: String!) {
  confirmPhone(otp: $otp) {
    success
    message
  }
}
    `;
export const useConfirmPhoneMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		ConfirmPhoneMutation,
		TError,
		ConfirmPhoneMutationVariables,
		TContext
	>
) =>
	useMutation<
		ConfirmPhoneMutation,
		TError,
		ConfirmPhoneMutationVariables,
		TContext
	>(
		(variables?: ConfirmPhoneMutationVariables) =>
			fetcher<ConfirmPhoneMutation, ConfirmPhoneMutationVariables>(
				client,
				ConfirmPhoneDocument,
				variables
			)(),
		options
	);
export const ConfirmEmailDocument = `
    mutation ConfirmEmail($token: String!) {
  confirmEmail(token: $token) {
    success
    message
  }
}
    `;
export const useConfirmEmailMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		ConfirmEmailMutation,
		TError,
		ConfirmEmailMutationVariables,
		TContext
	>
) =>
	useMutation<
		ConfirmEmailMutation,
		TError,
		ConfirmEmailMutationVariables,
		TContext
	>(
		(variables?: ConfirmEmailMutationVariables) =>
			fetcher<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
				client,
				ConfirmEmailDocument,
				variables
			)(),
		options
	);
export const MeDocument = `
    query Me {
  me {
    id
    firstName
    lastName
    email
    countryCode
    phone
    couponCode
  }
}
    `;
export const useMeQuery = <TData = MeQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: MeQueryVariables,
	options?: UseQueryOptions<MeQuery, TError, TData>
) =>
	useQuery<MeQuery, TError, TData>(
		["Me", variables],
		fetcher<MeQuery, MeQueryVariables>(client, MeDocument, variables),
		options
	);
export const ListBranchesDocument = `
    query ListBranches($where: BranchWhereInput) {
  listBranches(where: $where) {
    edges {
      id
      name
      address
      timezone
      operatingTimes {
        id
        closeTime
        openTime
        dayOfWeek
      }
      taxes {
        id
        name
        percentage
      }
    }
  }
}
    `;
export const useListBranchesQuery = <
	TData = ListBranchesQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: ListBranchesQueryVariables,
	options?: UseQueryOptions<ListBranchesQuery, TError, TData>
) =>
	useQuery<ListBranchesQuery, TError, TData>(
		["ListBranches", variables],
		fetcher<ListBranchesQuery, ListBranchesQueryVariables>(
			client,
			ListBranchesDocument,
			variables
		),
		options
	);
export const ListBranchesNameDocument = `
    query ListBranchesName {
  listBranches {
    edges {
      id
      name
      address
    }
  }
}
    `;
export const useListBranchesNameQuery = <
	TData = ListBranchesNameQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: ListBranchesNameQueryVariables,
	options?: UseQueryOptions<ListBranchesNameQuery, TError, TData>
) =>
	useQuery<ListBranchesNameQuery, TError, TData>(
		["ListBranchesName", variables],
		fetcher<ListBranchesNameQuery, ListBranchesNameQueryVariables>(
			client,
			ListBranchesNameDocument,
			variables
		),
		options
	);
export const GetBranchDocument = `
    query GetBranch($id: Int!) {
  getBranch(id: $id) {
    id
    name
    address
    latitude
    longitude
  }
}
    `;
export const useGetBranchQuery = <TData = GetBranchQuery, TError = unknown>(
	client: GraphQLClient,
	variables: GetBranchQueryVariables,
	options?: UseQueryOptions<GetBranchQuery, TError, TData>
) =>
	useQuery<GetBranchQuery, TError, TData>(
		["GetBranch", variables],
		fetcher<GetBranchQuery, GetBranchQueryVariables>(
			client,
			GetBranchDocument,
			variables
		),
		options
	);
export const ListActiveCampaignsDocument = `
    query ListActiveCampaigns($branchId: Int!, $discountLevel: Int) {
  listActiveCampaigns(branchId: $branchId, discountLevel: $discountLevel) {
    id
    name
    description
    startAt
    endAt
  }
}
    `;
export const useListActiveCampaignsQuery = <
	TData = ListActiveCampaignsQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables: ListActiveCampaignsQueryVariables,
	options?: UseQueryOptions<ListActiveCampaignsQuery, TError, TData>
) =>
	useQuery<ListActiveCampaignsQuery, TError, TData>(
		["ListActiveCampaigns", variables],
		fetcher<ListActiveCampaignsQuery, ListActiveCampaignsQueryVariables>(
			client,
			ListActiveCampaignsDocument,
			variables
		),
		options
	);
export const DeleteCartProductDocument = `
    mutation DeleteCartProduct($id: Int!) {
  deleteCartProduct(id: $id) {
    id
  }
}
    `;
export const useDeleteCartProductMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		DeleteCartProductMutation,
		TError,
		DeleteCartProductMutationVariables,
		TContext
	>
) =>
	useMutation<
		DeleteCartProductMutation,
		TError,
		DeleteCartProductMutationVariables,
		TContext
	>(
		(variables?: DeleteCartProductMutationVariables) =>
			fetcher<DeleteCartProductMutation, DeleteCartProductMutationVariables>(
				client,
				DeleteCartProductDocument,
				variables
			)(),
		options
	);
export const UpdateCartProductDocument = `
    mutation UpdateCartProduct($id: Int!, $values: UpdateCartProductInput!) {
  updateCartProduct(id: $id, values: $values) {
    id
    cartId
    productId
    quantity
    status
    addons {
      cartProductId
      productAddOnId
      productAddOn {
        id
        name
        price
      }
    }
    product {
      id
      name
      image
      price
      addons {
        id
        name
        price
      }
    }
  }
}
    `;
export const useUpdateCartProductMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		UpdateCartProductMutation,
		TError,
		UpdateCartProductMutationVariables,
		TContext
	>
) =>
	useMutation<
		UpdateCartProductMutation,
		TError,
		UpdateCartProductMutationVariables,
		TContext
	>(
		(variables?: UpdateCartProductMutationVariables) =>
			fetcher<UpdateCartProductMutation, UpdateCartProductMutationVariables>(
				client,
				UpdateCartProductDocument,
				variables
			)(),
		options
	);
export const AddProductToCartWithAddOnsDocument = `
    mutation AddProductToCartWithAddOns($productId: Int!, $branchId: Int!, $orderFlowId: Int!, $name: String!, $price: Int!, $quantity: Int, $productAddOnIds: [Int!]!, $subProducts: [CreateSubCartProductWithoutCartProductInput!]!, $coupon: AddProductToCartCoupon) {
  addProductToCartWithAddOns(
    productId: $productId
    quantity: $quantity
    productAddOnIds: $productAddOnIds
    subProducts: $subProducts
    branchId: $branchId
    orderFlowId: $orderFlowId
    name: $name
    price: $price
    coupon: $coupon
  ) {
    id
    cartId
    productId
    quantity
    status
    product {
      id
      name
      image
      price
    }
  }
}
    `;
export const useAddProductToCartWithAddOnsMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		AddProductToCartWithAddOnsMutation,
		TError,
		AddProductToCartWithAddOnsMutationVariables,
		TContext
	>
) =>
	useMutation<
		AddProductToCartWithAddOnsMutation,
		TError,
		AddProductToCartWithAddOnsMutationVariables,
		TContext
	>(
		(variables?: AddProductToCartWithAddOnsMutationVariables) =>
			fetcher<
				AddProductToCartWithAddOnsMutation,
				AddProductToCartWithAddOnsMutationVariables
			>(client, AddProductToCartWithAddOnsDocument, variables)(),
		options
	);
export const GetActiveCartProductDocument = `
    query GetActiveCartProduct {
  getActiveCartProduct {
    ...CartProductID
    id
    product {
      id
      name
      slug
      price
      image
      cloudinaryPublicId(publicIdSegments: 3)
      addons {
        id
        name
        price
      }
    }
    addons {
      cartProductId
      productAddOnId
      productAddOn {
        id
        name
        price
      }
    }
    quantity
  }
}
    ${CartProductIdFragmentDoc}`;
export const useGetActiveCartProductQuery = <
	TData = GetActiveCartProductQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: GetActiveCartProductQueryVariables,
	options?: UseQueryOptions<GetActiveCartProductQuery, TError, TData>
) =>
	useQuery<GetActiveCartProductQuery, TError, TData>(
		["GetActiveCartProduct", variables],
		fetcher<GetActiveCartProductQuery, GetActiveCartProductQueryVariables>(
			client,
			GetActiveCartProductDocument,
			variables
		),
		options
	);
export const GetActiveCartProductBaseDocument = `
    query GetActiveCartProductBase {
  getActiveCartProduct {
    ...CartProductID
    quantity
  }
}
    ${CartProductIdFragmentDoc}`;
export const useGetActiveCartProductBaseQuery = <
	TData = GetActiveCartProductBaseQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: GetActiveCartProductBaseQueryVariables,
	options?: UseQueryOptions<GetActiveCartProductBaseQuery, TError, TData>
) =>
	useQuery<GetActiveCartProductBaseQuery, TError, TData>(
		["GetActiveCartProductBase", variables],
		fetcher<
			GetActiveCartProductBaseQuery,
			GetActiveCartProductBaseQueryVariables
		>(client, GetActiveCartProductBaseDocument, variables),
		options
	);
export const ClearCartDocument = `
    mutation ClearCart {
  clearCart
}
    `;
export const useClearCartMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		ClearCartMutation,
		TError,
		ClearCartMutationVariables,
		TContext
	>
) =>
	useMutation<ClearCartMutation, TError, ClearCartMutationVariables, TContext>(
		(variables?: ClearCartMutationVariables) =>
			fetcher<ClearCartMutation, ClearCartMutationVariables>(
				client,
				ClearCartDocument,
				variables
			)(),
		options
	);
export const DeleteAndClearCartDocument = `
    mutation DeleteAndClearCart {
  deleteAndClearCart
}
    `;
export const useDeleteAndClearCartMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		DeleteAndClearCartMutation,
		TError,
		DeleteAndClearCartMutationVariables,
		TContext
	>
) =>
	useMutation<
		DeleteAndClearCartMutation,
		TError,
		DeleteAndClearCartMutationVariables,
		TContext
	>(
		(variables?: DeleteAndClearCartMutationVariables) =>
			fetcher<DeleteAndClearCartMutation, DeleteAndClearCartMutationVariables>(
				client,
				DeleteAndClearCartDocument,
				variables
			)(),
		options
	);
export const MyCartDocument = `
    query MyCart {
  myCart {
    id
  }
}
    `;
export const useMyCartQuery = <TData = MyCartQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: MyCartQueryVariables,
	options?: UseQueryOptions<MyCartQuery, TError, TData>
) =>
	useQuery<MyCartQuery, TError, TData>(
		["MyCart", variables],
		fetcher<MyCartQuery, MyCartQueryVariables>(
			client,
			MyCartDocument,
			variables
		),
		options
	);
export const MyCartTotalItemsDocument = `
    query MyCartTotalItems {
  myCart {
    id
    totalItems
  }
}
    `;
export const useMyCartTotalItemsQuery = <
	TData = MyCartTotalItemsQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: MyCartTotalItemsQueryVariables,
	options?: UseQueryOptions<MyCartTotalItemsQuery, TError, TData>
) =>
	useQuery<MyCartTotalItemsQuery, TError, TData>(
		["MyCartTotalItems", variables],
		fetcher<MyCartTotalItemsQuery, MyCartTotalItemsQueryVariables>(
			client,
			MyCartTotalItemsDocument,
			variables
		),
		options
	);
export const MyCartItemsDocument = `
    query MyCartItems {
  myCart {
    id
    totalProductsPrice
    promotionCarts {
      id
      promotion {
        id
        name
        cloudinaryPublicId
        rewards {
          discountUnit
          discountValue
        }
      }
      quantity
      promotionCartsOnProducts {
        quantity
        connectionType
        product {
          id
          name
          price
        }
        promotionRequirementId
        promotionRewardId
      }
    }
    productConnection {
      id
      quantity
      product {
        id
        name
        slug
        price
        image
        cloudinaryPublicId
        addons {
          id
          name
          price
        }
      }
      extraData
      price
      addons {
        cartProductId
        productAddOnId
        productAddOn {
          id
          name
          price
        }
      }
      subCartProducts {
        id
        product {
          id
          name
        }
        mealItemCategory {
          id
          quantity
          category {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const useMyCartItemsQuery = <TData = MyCartItemsQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: MyCartItemsQueryVariables,
	options?: UseQueryOptions<MyCartItemsQuery, TError, TData>
) =>
	useQuery<MyCartItemsQuery, TError, TData>(
		["MyCartItems", variables],
		fetcher<MyCartItemsQuery, MyCartItemsQueryVariables>(
			client,
			MyCartItemsDocument,
			variables
		),
		options
	);
export const MyCartItemIdsDocument = `
    query MyCartItemIds {
  myCart {
    promotionCarts {
      promotionId
      quantity
    }
    productConnection {
      quantity
      productId
      extraData
    }
  }
}
    `;
export const useMyCartItemIdsQuery = <
	TData = MyCartItemIdsQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: MyCartItemIdsQueryVariables,
	options?: UseQueryOptions<MyCartItemIdsQuery, TError, TData>
) =>
	useQuery<MyCartItemIdsQuery, TError, TData>(
		["MyCartItemIds", variables],
		fetcher<MyCartItemIdsQuery, MyCartItemIdsQueryVariables>(
			client,
			MyCartItemIdsDocument,
			variables
		),
		options
	);
export const ListCategoriesWithProductsDocument = `
    query ListCategoriesWithProducts {
  listCategories {
    edges {
      id
      name
      products {
        id
        name
        price
      }
    }
  }
}
    `;
export const useListCategoriesWithProductsQuery = <
	TData = ListCategoriesWithProductsQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: ListCategoriesWithProductsQueryVariables,
	options?: UseQueryOptions<ListCategoriesWithProductsQuery, TError, TData>
) =>
	useQuery<ListCategoriesWithProductsQuery, TError, TData>(
		["ListCategoriesWithProducts", variables],
		fetcher<
			ListCategoriesWithProductsQuery,
			ListCategoriesWithProductsQueryVariables
		>(client, ListCategoriesWithProductsDocument, variables),
		options
	);
export const ValidateCouponDocument = `
    mutation ValidateCoupon($input: ValidateCouponInput!) {
  validateCoupon(input: $input) {
    error
    coupon {
      code
      userName
      discountLevel
    }
  }
}
    `;
export const useValidateCouponMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		ValidateCouponMutation,
		TError,
		ValidateCouponMutationVariables,
		TContext
	>
) =>
	useMutation<
		ValidateCouponMutation,
		TError,
		ValidateCouponMutationVariables,
		TContext
	>(
		(variables?: ValidateCouponMutationVariables) =>
			fetcher<ValidateCouponMutation, ValidateCouponMutationVariables>(
				client,
				ValidateCouponDocument,
				variables
			)(),
		options
	);
export const OrderHistoryDocument = `
    query orderHistory {
  historyOrders {
    id
    code
    status
    createdAt
    scheduledAt
    branch {
      name
    }
    snapshot {
      order_flow
      notes
      fee_breakdown {
        subtotal
        fees {
          name
          value
          percentage
        }
        discounts {
          name
          value
          unit
          price
        }
        taxes {
          name
          value
          percentage
        }
      }
      items {
        product_id
        name
        quantity
        price
        modifiers {
          cart_modifier_id
          modifier_id
          name
          price
          quantity
        }
        coupon {
          code
          discount_value
          original_price
        }
      }
    }
  }
}
    `;
export const useOrderHistoryQuery = <
	TData = OrderHistoryQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: OrderHistoryQueryVariables,
	options?: UseQueryOptions<OrderHistoryQuery, TError, TData>
) =>
	useQuery<OrderHistoryQuery, TError, TData>(
		["orderHistory", variables],
		fetcher<OrderHistoryQuery, OrderHistoryQueryVariables>(
			client,
			OrderHistoryDocument,
			variables
		),
		options
	);
export const CheckoutDocument = `
    mutation Checkout($input: CheckoutInput!) {
  checkout(input: $input) {
    invoiceUrl
    order {
      code
    }
  }
}
    `;
export const useCheckoutMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		CheckoutMutation,
		TError,
		CheckoutMutationVariables,
		TContext
	>
) =>
	useMutation<CheckoutMutation, TError, CheckoutMutationVariables, TContext>(
		(variables?: CheckoutMutationVariables) =>
			fetcher<CheckoutMutation, CheckoutMutationVariables>(
				client,
				CheckoutDocument,
				variables
			)(),
		options
	);
export const ListProductsDocument = `
    query ListProducts($take: Int!) {
  listProducts(take: $take) {
    edges {
      id
      name
      slug
      description
      image
      cloudinaryPublicId
      price
      category {
        id
        name
      }
      labels {
        id
        name
      }
    }
  }
}
    `;
export const useListProductsQuery = <
	TData = ListProductsQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables: ListProductsQueryVariables,
	options?: UseQueryOptions<ListProductsQuery, TError, TData>
) =>
	useQuery<ListProductsQuery, TError, TData>(
		["ListProducts", variables],
		fetcher<ListProductsQuery, ListProductsQueryVariables>(
			client,
			ListProductsDocument,
			variables
		),
		options
	);
export const GetProductDocument = `
    query GetProduct($id: Int!, $branchId: Int!, $couponCode: String) {
  getProduct(id: $id, branchId: $branchId, couponCode: $couponCode) {
    ... on Product {
      id
      name
      slug
      description
      image
      cloudinaryPublicId
      price
      addons {
        id
        name
        price
      }
      labels {
        id
        name
      }
      mealItemProducts {
        quantity
        childProduct {
          id
          name
        }
      }
      coupon {
        code
        discountValue
        quantity
      }
      mealItemCategories {
        id
        quantity
        category {
          id
          name
        }
      }
    }
  }
}
    `;
export const useGetProductQuery = <TData = GetProductQuery, TError = unknown>(
	client: GraphQLClient,
	variables: GetProductQueryVariables,
	options?: UseQueryOptions<GetProductQuery, TError, TData>
) =>
	useQuery<GetProductQuery, TError, TData>(
		["GetProduct", variables],
		fetcher<GetProductQuery, GetProductQueryVariables>(
			client,
			GetProductDocument,
			variables
		),
		options
	);
export const ListMenuSectionsDocument = `
    query ListMenuSections($branchId: Int!, $couponCode: String) {
  listMenuSections(branchId: $branchId, couponCode: $couponCode) {
    special {
      id
      name
      position
      items {
        id
        name
        image
        cloudinaryPublicId
        slug
        description
        position
        price
        labels {
          id
          name
          position
        }
        coupon {
          code
          discountValue
        }
      }
    }
    main {
      id
      name
      position
      items {
        id
        name
        image
        cloudinaryPublicId
        slug
        description
        position
        price
        labels {
          id
          name
          position
        }
      }
    }
  }
}
    `;
export const useListMenuSectionsQuery = <
	TData = ListMenuSectionsQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables: ListMenuSectionsQueryVariables,
	options?: UseQueryOptions<ListMenuSectionsQuery, TError, TData>
) =>
	useQuery<ListMenuSectionsQuery, TError, TData>(
		["ListMenuSections", variables],
		fetcher<ListMenuSectionsQuery, ListMenuSectionsQueryVariables>(
			client,
			ListMenuSectionsDocument,
			variables
		),
		options
	);
export const AddPromotionToCartDocument = `
    mutation AddPromotionToCart($values: AddPromotionToCartInput!) {
  addPromotionToCart(values: $values) {
    id
  }
}
    `;
export const useAddPromotionToCartMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		AddPromotionToCartMutation,
		TError,
		AddPromotionToCartMutationVariables,
		TContext
	>
) =>
	useMutation<
		AddPromotionToCartMutation,
		TError,
		AddPromotionToCartMutationVariables,
		TContext
	>(
		(variables?: AddPromotionToCartMutationVariables) =>
			fetcher<AddPromotionToCartMutation, AddPromotionToCartMutationVariables>(
				client,
				AddPromotionToCartDocument,
				variables
			)(),
		options
	);
export const UpdatePromotionCartDocument = `
    mutation UpdatePromotionCart($id: Int!, $values: UpdatePromotionCartInput!) {
  updatePromotionCart(id: $id, values: $values) {
    id
    quantity
  }
}
    `;
export const useUpdatePromotionCartMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		UpdatePromotionCartMutation,
		TError,
		UpdatePromotionCartMutationVariables,
		TContext
	>
) =>
	useMutation<
		UpdatePromotionCartMutation,
		TError,
		UpdatePromotionCartMutationVariables,
		TContext
	>(
		(variables?: UpdatePromotionCartMutationVariables) =>
			fetcher<
				UpdatePromotionCartMutation,
				UpdatePromotionCartMutationVariables
			>(client, UpdatePromotionCartDocument, variables)(),
		options
	);
export const DeletePromotionCartDocument = `
    mutation DeletePromotionCart($id: Int!) {
  deletePromotionCart(id: $id) {
    id
  }
}
    `;
export const useDeletePromotionCartMutation = <
	TError = unknown,
	TContext = unknown
>(
	client: GraphQLClient,
	options?: UseMutationOptions<
		DeletePromotionCartMutation,
		TError,
		DeletePromotionCartMutationVariables,
		TContext
	>
) =>
	useMutation<
		DeletePromotionCartMutation,
		TError,
		DeletePromotionCartMutationVariables,
		TContext
	>(
		(variables?: DeletePromotionCartMutationVariables) =>
			fetcher<
				DeletePromotionCartMutation,
				DeletePromotionCartMutationVariables
			>(client, DeletePromotionCartDocument, variables)(),
		options
	);
export const ListPromotionsDocument = `
    query ListPromotions($where: PromotionWhereInput) {
  listPromotions(where: $where) {
    edges {
      id
      name
      validFrom
      validUntil
      image
      cloudinaryPublicId
    }
  }
}
    `;
export const useListPromotionsQuery = <
	TData = ListPromotionsQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables?: ListPromotionsQueryVariables,
	options?: UseQueryOptions<ListPromotionsQuery, TError, TData>
) =>
	useQuery<ListPromotionsQuery, TError, TData>(
		["ListPromotions", variables],
		fetcher<ListPromotionsQuery, ListPromotionsQueryVariables>(
			client,
			ListPromotionsDocument,
			variables
		),
		options
	);
export const GetPromotionDocument = `
    query GetPromotion($id: Int!) {
  getPromotion(id: $id) {
    id
    name
    validFrom
    validUntil
    image
    cloudinaryPublicId
    requirements {
      id
      quantity
      productId
      product {
        name
      }
      categoryId
      category {
        name
      }
    }
    rewards {
      id
      quantity
      productId
      product {
        id
        name
      }
      categoryId
      category {
        id
        name
      }
      discountUnit
      discountValue
    }
  }
}
    `;
export const useGetPromotionQuery = <
	TData = GetPromotionQuery,
	TError = unknown
>(
	client: GraphQLClient,
	variables: GetPromotionQueryVariables,
	options?: UseQueryOptions<GetPromotionQuery, TError, TData>
) =>
	useQuery<GetPromotionQuery, TError, TData>(
		["GetPromotion", variables],
		fetcher<GetPromotionQuery, GetPromotionQueryVariables>(
			client,
			GetPromotionDocument,
			variables
		),
		options
	);
export const ListTaxesDocument = `
    query listTaxes {
  listTaxes {
    edges {
      name
      percentage
      id
    }
  }
}
    `;
export const useListTaxesQuery = <TData = ListTaxesQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: ListTaxesQueryVariables,
	options?: UseQueryOptions<ListTaxesQuery, TError, TData>
) =>
	useQuery<ListTaxesQuery, TError, TData>(
		["listTaxes", variables],
		fetcher<ListTaxesQuery, ListTaxesQueryVariables>(
			client,
			ListTaxesDocument,
			variables
		),
		options
	);
