import dayjs from "dayjs";
// dependent on utc plugin
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { Branch } from "src/generated/graphql";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

export const isBranchOpen = (
	branch: Pick<Branch, "operatingTimes" | "timezone">
) => {
	const nowDayjs = dayjs().tz(branch.timezone);
	const YYYY_MM_DD = nowDayjs.format("YYYY-MM-DD");
	const todayOperatingTime = branch.operatingTimes.find(
		(ot) => ot.dayOfWeek === nowDayjs.day()
	);
	if (!todayOperatingTime) {
		return false;
	}
	const storeOpenTime = dayjs.tz(
		`${YYYY_MM_DD} ${todayOperatingTime.openTime}`,
		branch.timezone
	);
	const storeCloseTime = dayjs.tz(
		`${YYYY_MM_DD} ${todayOperatingTime.closeTime}`,
		branch.timezone
	);
	const overMidnight = storeCloseTime.isBefore(storeOpenTime);
	if (overMidnight) {
		return nowDayjs.isAfter(storeOpenTime) || nowDayjs.isBefore(storeCloseTime);
	}
	// inclusive openTime and exclusive closeTime
	return nowDayjs.isBetween(storeOpenTime, storeCloseTime, null, "[)");
};
