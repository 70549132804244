import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";

import { ListBranchesQuery, useListBranchesQuery } from "src/generated/graphql";
import { EW_BURGERS_SHOP_ID } from "src/lib/constants";
import { gqlClient } from "src/lib/gqlClient";
import { isBranchOpen } from "src/utils/date";

type BranchContextProps = {
	branchId: string;
	setBranchId: Dispatch<SetStateAction<string>>;
	branches: ListBranchesQuery["listBranches"]["edges"];
	openedBranchesMap: { [id: string]: boolean };
	allBranchClosed: boolean;
	canOrder: boolean;
};

export const BranchContext = createContext<
	BranchContextProps | Record<string, never>
>({});

export const BranchProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const [branchId, setBranchId] = useState(() => {
		if (typeof window === "undefined") {
			return "";
		}
		return window.localStorage.getItem("branchId") || "";
	});
	useEffect(() => {
		window.localStorage.setItem("branchId", branchId);
	}, [branchId]);
	const { data = { listBranches: { edges: [] } } } = useListBranchesQuery(
		gqlClient,
		{ where: { shopId: { equals: EW_BURGERS_SHOP_ID } } }
	);
	const openedBranchesMap = data.listBranches.edges.reduce<{
		[id: string]: boolean;
	}>((accum, branch) => {
		return {
			...accum,
			[branch.id]: isBranchOpen(branch),
		};
	}, {});

	// By default set to false to prevent false warning messages to be displayed when data is still loading
	const allBranchClosed =
		data.listBranches.edges.length > 0
			? Object.values(openedBranchesMap).every((isOpened) => !isOpened)
			: false;

	return (
		<BranchContext.Provider
			value={{
				branchId,
				setBranchId,
				branches: data.listBranches.edges,
				openedBranchesMap,
				allBranchClosed,
				canOrder: branchId !== "" && openedBranchesMap[branchId],
			}}
		>
			{children}
		</BranchContext.Provider>
	);
};

export const useSelectedBranch = () => {
	const context = useContext(BranchContext);
	if (context === null) {
		throw new Error(
			"Cannot found BranchContext in the React tree. Render <BranchProvider /> in the parent tree!"
		);
	}
	return context;
};
