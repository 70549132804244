import React, { PropsWithChildren } from "react";

import ConfirmationContext, {
	ConfirmationOptions,
} from "./ConfirmationContext";
import ConfirmationDialog from "./ConfirmationDialog";

const defaultOptions = {
	title: "Confirmation Dialog",
	description: "",
	yesText: "Yes",
	cancelText: "Cancel",
};

interface Props extends PropsWithChildren<any> {}
const ConfirmationProvider: React.FC<Props> = ({ children }) => {
	const [confirmationState, setConfirmationState] =
		React.useState<ConfirmationOptions | null>(null);
	const awaitingPromiseRef = React.useRef<{
		resolve: () => void;
		reject: () => void;
	}>();
	const openConfirmation = (options: ConfirmationOptions) => {
		setConfirmationState({
			...defaultOptions,
			...options,
		});
		return new Promise<void>((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject };
		});
	};
	const handleClose = () => {
		awaitingPromiseRef.current?.reject();
		setConfirmationState(null);
	};
	const handleSubmit = () => {
		awaitingPromiseRef.current?.resolve();
		setConfirmationState(null);
	};
	return (
		<>
			<ConfirmationContext.Provider value={openConfirmation}>
				{children}
			</ConfirmationContext.Provider>
			<ConfirmationDialog
				open={Boolean(confirmationState)}
				onClose={handleClose}
				yesText={confirmationState?.yesText || ""}
				cancelText={confirmationState?.cancelText || ""}
				onSubmit={handleSubmit}
				title={confirmationState?.title || ""}
				description={confirmationState?.description}
			/>
		</>
	);
};

export default ConfirmationProvider;
