import React, { PropsWithChildren, useState } from "react";

import { SelectBranchModalContext } from "./SelectBranchModalContext";

interface Props extends PropsWithChildren<any> {
	initialState: {
		open: boolean;
	};
}
const SelectBranchModalProvider: React.FC<Props> = ({
	initialState,
	children,
}) => {
	const [open, setOpen] = useState(initialState.open);
	const onClose = () => {
		setOpen(false);
	};
	const onOpen = () => {
		setOpen(true);
	};
	return (
		<SelectBranchModalContext.Provider value={{ onClose, open, onOpen }}>
			{children}
		</SelectBranchModalContext.Provider>
	);
};

export default SelectBranchModalProvider;
