import ReactGA4 from "react-ga4";

import { EventArgs } from "./types";

const env = process.env.GATSBY_ENV;
const ProdEnv = "prod";

export class GoogleAnalytics {
	ga4: typeof ReactGA4;

	constructor() {
		this.ga4 = ReactGA4;
	}

	initGa4 = () => {
		if (env === ProdEnv) {
			this.ga4.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID!);
		}
	};

	pageviewGa4 = (pathname: string) => {
		if (env === ProdEnv) {
			this.ga4.send({ hitType: "pageview", page: pathname });
		}
	};

	eventGa4 = (eventOptions: EventArgs, params?: any) => {
		if (env === ProdEnv) {
			this.ga4.event(eventOptions.action, {
				...params,
				event_category: eventOptions.category,
				event_label: eventOptions.label,
			});
		}
	};
}
