import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cls from "classnames";

interface Props
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	open: boolean;
	onClose: () => void;
	header?: JSX.Element;
	withCloseIcon?: boolean;
	footer?: JSX.Element;
	icon?: JSX.Element;
	disableOverlayClick?: boolean;
}
const Dialog: React.FC<Props> = ({
	open,
	onClose,
	children,
	footer,
	icon,
	disableOverlayClick = false,
	header,
	withCloseIcon,
	...rest
}) => {
	if (!open) return null;
	return (
		<div
			className="fixed z-[80] inset-0 overflow-y-auto"
			aria-labelledby="modal-title"
			role="dialog"
			aria-modal="true"
			{...rest}
		>
			<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<div
					className={cls(
						"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
						{
							"cursor-pointer": !disableOverlayClick,
						}
					)}
					data-label="backdrop"
					aria-hidden="true"
					onClick={!disableOverlayClick ? onClose : undefined}
				/>

				<span
					className="hidden sm:inline-block sm:align-middle sm:h-screen"
					aria-hidden="true"
				>
					&#8203;
				</span>

				<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
					<div className="flex flex-col gap-8 bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
						<div className="flex items-center justify-between">
							{header}
							{withCloseIcon && (
								<button type="button">
									<FontAwesomeIcon icon="xmark" size="xl" onClick={onClose} />
								</button>
							)}
						</div>
						<div className="sm:flex sm:items-start">
							{icon && (
								<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
									{icon}
								</div>
							)}
							<div className="w-full mt-3">{children}</div>
						</div>
					</div>
					<div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
						{footer}
					</div>
				</div>
			</div>
		</div>
	);
};

interface DialogTitleProps
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLHeadingElement>,
		HTMLHeadingElement
	> {}
export const DialogTitle: React.FC<DialogTitleProps> = ({
	children,
	...rest
}) => (
	<h3 className="text-lg leading-6 font-medium text-gray-900" {...rest}>
		{children}
	</h3>
);

interface DialogContentProps
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {}
export const DialogContent: React.FC<DialogContentProps> = ({
	className,
	children,
	...rest
}) => {
	return (
		<div className={cls("mt-2", className)} {...rest}>
			{children}
		</div>
	);
};

interface DialogContentTextProps
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {}
export const DialogContentText: React.FC<DialogContentTextProps> = ({
	className,
	children,
	...rest
}) => {
	return (
		<p className={cls("text-sm", className)} {...rest}>
			{children}
		</p>
	);
};

export default Dialog;
