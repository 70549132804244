exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-index-tsx": () => import("./../../../src/pages/auth/index.tsx" /* webpackChunkName: "component---src-pages-auth-index-tsx" */),
  "component---src-pages-cart-checkout-index-tsx": () => import("./../../../src/pages/cart/checkout/index.tsx" /* webpackChunkName: "component---src-pages-cart-checkout-index-tsx" */),
  "component---src-pages-cart-checkout-post-checkout-tsx": () => import("./../../../src/pages/cart/checkout/post-checkout.tsx" /* webpackChunkName: "component---src-pages-cart-checkout-post-checkout-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-confirm-index-tsx": () => import("./../../../src/pages/confirm/index.tsx" /* webpackChunkName: "component---src-pages-confirm-index-tsx" */),
  "component---src-pages-history-index-tsx": () => import("./../../../src/pages/history/index.tsx" /* webpackChunkName: "component---src-pages-history-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-confirmed-tsx": () => import("./../../../src/pages/order/confirmed.tsx" /* webpackChunkName: "component---src-pages-order-confirmed-tsx" */),
  "component---src-pages-payment-index-tsx": () => import("./../../../src/pages/payment/index.tsx" /* webpackChunkName: "component---src-pages-payment-index-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product/[...].tsx" /* webpackChunkName: "component---src-pages-product-tsx" */)
}

