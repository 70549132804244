import { DocumentNode, OperationDefinitionNode } from "graphql";
import { GraphQLClient } from "graphql-request";
import gql from "graphql-tag";

const getOperationName = (doc: DocumentNode) => {
	const operation = doc.definitions.filter(
		(def) => def.kind === "OperationDefinition"
	)[0] as OperationDefinitionNode;
	return operation?.name?.value;
};
const BACKEND_ENDPOINT = process.env.GATSBY_API_URL || "/api";

export const gqlClient = new GraphQLClient(`${BACKEND_ENDPOINT}/graphql`, {
	credentials: "include",
	fetch: (uri: string, options: RequestInit) => {
		if (typeof options?.body === "string") {
			const bodyObject: Record<string, unknown> = JSON.parse(options.body);
			// eslint-disable-next-line no-prototype-builtins
			if (bodyObject.hasOwnProperty("query")) {
				const operationName = getOperationName(gql(bodyObject.query as string));
				if (typeof operationName === "string") {
					return fetch(`${uri}?operation=${operationName}`, options);
				}
			}
		}
		return fetch(uri, options);
	},
});
