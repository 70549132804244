/* eslint-disable import/prefer-default-export, no-console */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { registry } from "./src/lib/registry";
import "./src/styles/index.css";

export { wrapRootElement } from "./gatsby-ssr";

console.log("NODE_ENV: ", process.env.NODE_ENV);
console.log("GATSBY_API_URL: ", process.env.GATSBY_API_URL);
console.log("GATSBY_ENV: ", process.env.GATSBY_ENV);

library.add(fas);
registry.ga.initGa4();
